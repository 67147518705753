<template>
  <div
    class="bosch-header"
    :class="{
      visible,
      splash: context === `splashScreen`,
      pro: context === `pro`,
      diy: context === `diy`,
      mobile: $breakpoints.isMobile,
    }"
  >
    <img class="bosch-rainbow" :src="require(`@/assets/header/head.jpeg`)" alt="bosch-rainbow" />
    <div class="bosch-header-content">
      <img class="bosch-logo" :src="require(`@/assets/header/logo.png`)" alt="bosch-logo" v-show="context !== `pro`">
      <img class="bosch-logo" :src="require(`@/assets/header/logo_white.png`)" alt="bosch-logo" v-show="context === `pro`">
      <div
        class="bosch-header-buttons"
        :class="{
          visible: isInExperience && visible
        }"
      >
        <label
          class="bosch-experience-switch"
          for="current-experience"
        >
          <div>DIY</div>
          <div class="toggle-switch-wrapper">
            <div class="toggle-switch-circle" />
          </div>
          <div>PRO</div>
          <input type="checkbox" name="current-experience" id="current-experience" @change="switchExperience" />
        </label>
        <button class="toggle-button toggle-sound" :class="{ clicked: soundButtonClicked }" @click="toggleSound">
          <div class="img-wrapper">
            <img :src="require(`@/assets/icons/sound_on.png`)"  :class="{ visible: !muted }" alt="sound-on-icon"/>
            <img :src="require(`@/assets/icons/sound_off.png`)" :class="{ visible: muted }" alt="sound-off-icon"/>
          </div>
          <p>Sound {{ muted ? `OFF` : `ON` }}</p>
        </button>
        <button class="toggle-button toggle-fullscreen" @click="toggleFullScreen">
          <img :src="require(`@/assets/icons/fullscreen.png`)" alt="fullscreen-icon" />
          <p>Plein écran</p>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    context: {
      type: String,
      required: false,
      default: () => `splashScreen`
    },
    muted: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    isInExperience: {
      type: Boolean,
      required: false,
      default: () => false,
    },
  },

  data: () => ({
    soundButtonClicked: false,
  }),

  methods: {
    switchExperience() {
      this.$emit(`switch-experience`);
    },
    toggleSound() {
      this.$emit(`toggle-sound`);
      this.soundButtonClicked = true;
      setTimeout(() => {
        this.soundButtonClicked = false;
      }, 1500);
    },
    toggleFullScreen() {
      this.$emit(`toggle-fullscreen`);
    },
  },
};
</script>

<style lang="scss" scoped>
$header-height: 70px;
$header-height-mobile: 60px;

.bosch-header {
  position: fixed;
  // z-index: 200;
  top: 0;
  left: 0;
  right: 0;
  background-color: #ffffff;
  color: #000000;
  transition: opacity 0.5s ease-in-out, background-color 0.5s ease-in-out, color 0.5s ease-in-out;
  opacity: 0;
  pointer-events: none;

  &, * {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  &.visible {
    opacity: 1;
    pointer-events: all;
  }

  .bosch-rainbow {
    width: 100%;
    display: block;
    height: 15px;
  }

  .bosch-header-content {
    width: 100%;
    height: $header-height;
    padding-left: 3%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .bosch-logo {
      max-width: 150px;
    }

    .bosch-header-buttons {
      align-self: stretch;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      opacity: 0;
      pointer-events: none;
      transition: opacity 0.5s ease-in-out;

      &.visible {
        opacity: 1;
        pointer-events: all;
      }
      .bosch-experience-switch {
        display: flex;
        padding: 0 30px;

        .toggle-switch-wrapper {
          width: 2.2em;
          margin: 0 10px;
          height: 1.2em;
          padding: 0.2em;
          background: #006C3A;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 1.2em;
          cursor: pointer;

          transition: background-color 0.5s ease-in-out;

          .toggle-switch-circle {
            width: 0.8em;
            height: 0.8em;
            border-radius: 0.8em;
            background-color: #ffffff;
            transition: background-color 0.5s ease-in-out, transform 0.5s ease-in-out;
            transform: translateX(-50%);
          }
        }

        input {
          display: none;
        }
      }

      .toggle-button {
        height: $header-height;
        width: $header-height;
        color: #000000;
        // aspect-ratio: 1 / 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border: none;
        cursor: pointer;
        font-size: 9px;
        padding: 0;
        padding-top: 0.5em;
        overflow: hidden;

        img {
          height: 27px;
          transition: opacity 0.3s ease-in-out;
        }

        p {
          font-size: 9px;
          opacity: 0;
          transform: translateY(3em);

          transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
        }

        &.toggle-sound {
          background-color: #d0d0d0;

          .img-wrapper {
            height: 27px;
            position: relative;
            transform: translateY(1.1em);
            transition: transform 0.5s ease-in-out;

            img {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              opacity: 0;

              &.visible {
                opacity: 1;
              }
            }
          }
        }

        &.toggle-fullscreen {
          background-color: #000000;
          color: #ffffff;

          img {
            transform: translateY(1.1em);
            transition: transform 0.5s ease-in-out, opacity 0.3s ease-in-out;
          }
        }
      }
    }
  }

  &.pro {
    background-color: #013b6a;
    color: #ffffff;
    .bosch-header-content {
      .bosch-header-buttons {
        .bosch-experience-switch {
          .toggle-switch-wrapper {
            background-color: #ffffff;

            .toggle-switch-circle {
              background-color: #013b6a;
              transform: translateX(50%);
            }
          }
        }
      }
    }
  }

  &:not(.mobile) {
    .toggle-button:hover {
      &:hover {
        &.toggle-sound {
          .img-wrapper {
            transform: translateY(0);
          }
        }
        &.toggle-fullscreen {
          img {
            transform: translateY(0);
          }
        }
        p {
          opacity: 1;
          transform: translateY(0);
        }
      }
    }
  }

  &.mobile {
    .bosch-rainbow {
      height: 15px;
    }

    .bosch-header-content {
      height: $header-height-mobile;

      .bosch-logo {
        width: 28%;
        max-width: 110px;
      }

      .bosch-header-buttons {
        .bosch-experience-switch {
          padding: 0 15px;
          font-size: 3vw;
          // margin: 0 15px;
        }

        .toggle-button {
          width: $header-height-mobile;
          height: $header-height-mobile;

          .img-wrapper {
            transform: translateY(1.1em);
          }

          img {
            height: 20px;
          }

          p {
            transform: translateY(3em);
          }

          &.toggle-fullscreen {
            display: none;
          }

          // &:hover {
          //   img {
          //     transform: translate(-50%, -50%);
          //   }
          //   p {
          //     opacity: 0;
          //     transform: translateY(3em);
          //   }
          // }

          &.clicked {
            .img-wrapper {
              transform: translateY(0);
            }

            p {
              opacity: 1;
              transform: translateY(0);
            }
          }
        }
      }
    }
  }
}
</style>