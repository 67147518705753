
import Vue from 'vue';

const IS_TABLET = /(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(window.navigator.userAgent);
const IS_MOBILE = /Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(window.navigator.userAgent);

const computeIsMobile = width => width < 700;
const computeIsMobileDevice = width => {
  return computeIsMobile(width) && (IS_TABLET || IS_MOBILE);
};

const DEFAULT_WIDTH = window.innerWidth;

class Breakpoints {
  constructor() {
    this.VM = new Vue({
      data: () => ({
        width: DEFAULT_WIDTH,
        isMobile: computeIsMobile(DEFAULT_WIDTH),
        isMobileDevice: computeIsMobileDevice(DEFAULT_WIDTH),
      }),
      watch: {
        width(width) {
          this.isMobile = computeIsMobile(width);
          this.isMobileDevice = computeIsMobileDevice(width);
        },
      },
    });
  }
  get state() {
    return this.VM.$data;
  }
}

export default {
  install(Vue) {
    const breakpoints = new Breakpoints();
    let timer = null;
    window.addEventListener(`resize`, () => {
      clearTimeout(timer);
      timer = setTimeout(() => breakpoints.state.width = window.innerWidth, 50)
    });
    Vue.prototype.$breakpoints = breakpoints.state;
  },
};
