<template>
  <carousel
    v-model="currentImage"
    :per-page="1"
    :navigationEnabled="navigationEnabled"
    :navigationNextLabel="navRight"
    :navigationPrevLabel="navLeft"
    :paginationEnabled="true"
    :paginationActiveColor="paginationColorActive"
    :paginationColor="paginationColor"
    :paginationSize="paginationSize"
    :paginationPadding="paginationPadding"
    :loop="true"
    :speed="speed"
    paginationPosition="bottom-overlay"
    @transition-start="onSlide"
    ref="vueCarousel"
  >
    <slide
      v-for="(img, idx) in images"
      :key="idx"
    >
      <img :class="`${context.length ? context + '-' : ''}carousel-img`" :src="img.src" :alt="`carousel-slide-${idx}`" />
    </slide>
  </carousel>
</template>

<script>
import { Carousel, Slide } from 'vue-carousel';

const DEFAULT_CAROUSEL_SPEED = 500;

export default {
  components: {
    Carousel,
    Slide
  },

  props: {
    images: {
      type: Array,
      required: true,
    },
    context: {
      type: String,
      required: false,
      default: () => ``
    },
    navigationEnabled: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    navigationRight: {
      type: String,
      required: false,
      default: () => null,
    },
    navigationLeft: {
      type: String,
      required: false,
      default: () => null,
    },
    paginationColor: {
      type: String,
      required: false,
      default: () => `#fff`,
    },
    paginationColorActive: {
      type: String,
      required: false,
      default: () => `#000`,
    },
    paginationSize: {
      type: Number,
      required: false,
      default: () => 10,
    },
    paginationPadding: {
      type: Number,
      required: false,
      default: () => 10,
    },
  },

  data: () => ({
    currentImage: 0,
    speed: DEFAULT_CAROUSEL_SPEED,
    canLoopToStart: false,
  }),

  computed: {
    navRight() {
      if (this.navigationRight) {
        return this.navigationRight;
      } else {
        const rightChevron = require('@/assets/icons/chevron_right_black.svg');
        return `<img class="carousel-arrow" src="${rightChevron}" alt="next-slide"/>`;
      }
    },
    navLeft() {
      if (this.navigationLeft) {
        return this.navigationLeft;
      } else {
        const leftChevron = require('@/assets/icons/chevron_left_black.svg');
        return `<img class="carousel-arrow" src="${leftChevron}" alt="prev-slide"/>`;
      }
    },
  },

  methods: {
    initCarousel() {
      this.speed = 0;
      this.currentImage = 0;
      setTimeout(() => {
        this.speed = DEFAULT_CAROUSEL_SPEED;
      });
    },
    onSlide() {
      if (this.$refs.vueCarousel.currentPage === (this.$refs.vueCarousel.slideCount - 1)) {
        if (this.canLoopToStart) {
          this.$refs.vueCarousel.goToPage(0);
          this.canLoopToStart = false;
        } else {
          this.canLoopToStart = true;
        }
      }
    }
  },

  watch: {
    images() {
      this.initCarousel();
    },
  },
};
</script>

<style lang="scss" scoped>

</style>