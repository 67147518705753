export default [
  {
    name: `diy`,
    src: require(`@/assets/sounds/diy_sound.mp3`),
  },
  {
    name: `pro`,
    src: require(`@/assets/sounds/pro_sound.mp3`),
  },
];
