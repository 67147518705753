
const removeDisabledElements = src => {
  let res = null;
  try {
    res = JSON.parse(JSON.stringify(src));
  } catch (e) {} // eslint-disable-line

  if (res instanceof Array) {
    const indexesToDelete = [];
    res.forEach((el, i) => {
      if (el instanceof Array) {
        res[i] = removeDisabledElements(el);
      } else if (typeof el === `object`) {
        const newEl = removeDisabledElements(el);
        if (newEl === undefined) {
          indexesToDelete.push(i);
        } else {
          res[i] = newEl;
        }
      }
    });
    indexesToDelete.sort().reverse().forEach(i => res.splice(i, 1));
  } else if (res !== null && typeof res === `object`) {
    if (res?.enabled === false) {
      res = undefined;
    } else {
      Object.keys(res).forEach(key => {
        const el = res[key];
        if (typeof el === `object`) {
          const newEl = removeDisabledElements(el);
          if (newEl === undefined) {
            delete res[key];
          } else {
            res[key] = newEl;
          }
        }
      });
    }
  }

  return res;
};

const mergeObjects = (obj1, obj2) => {
  const res = JSON.parse(JSON.stringify(obj1));
  if (typeof obj2 === `object`) {
    Object.keys(obj2).forEach(key => {
      if (res[key] instanceof Array) {
        res[key] = mergeArrays(res[key], obj2[key]);
      } else if (typeof res[key] === `object` && res[key] !== null) {
        res[key] = mergeObjects(res[key], obj2[key]);
      } else if (typeof obj2[key] !== `string` || obj2[key].length) {
        res[key] = obj2[key];
      }
    });
  }
  return res;
};

const mergeManyObjects = (...args) => {
  let res = {};
  args.forEach((arg, i) => {
    const obj2 = args[i + 1]
    if (i === 0) {
      res = mergeObjects(arg, obj2);
    } else if (obj2) {
      res = mergeObjects(res, obj2);
    }
  });
  return res;
};

const mergeArrays = (arr1, arr2) => {
  let res = JSON.parse(JSON.stringify(arr1));
  const firstEl = res[0];
  if (arr2 === undefined || arr2 === null || !arr2.length) {
    return res;
  }

  if (!(typeof firstEl === `object`)) {
    res = arr2;
  } else if (arr2 !== undefined && arr2.length) {
    const indexesToDelete = [];
    res.forEach((el, i) => {
      if (el.to === undefined && el.name === undefined && el.value === undefined) {
        indexesToDelete.push(i);
      }
    });
    indexesToDelete.sort().reverse().forEach(i => res.splice(i, 1));
    arr2.forEach(el => {
      let resElIndex = -1;
      let searchKey = null;
      if (el.to !== undefined) { searchKey = `to` }
      if (el.name !== undefined) { searchKey = `name` }
      if (el.value !== undefined) { searchKey = `value` }

      if (searchKey) {
        resElIndex = res.findIndex(resEl => resEl[searchKey] === el[searchKey])
      }

      if (resElIndex !== -1) {
        res[resElIndex] = mergeObjects(res[resElIndex], el);
      } else {
        res.push(el);
      }
    });
  }
  return res;
};

const mergeManyArrays = (...args) => {
  let res = []
  args.forEach((arg, i) => {
    const arr2 = args[i + 1]
    if (i === 0) {
      res = mergeArrays(arg, arr2);
    } else if (arr2) {
      res = mergeArrays(res, arr2);
    }
  });
  return res;
};

export default function (...args) {
  let res;

  if (!args.length || !args[0]) { return res; }

  if (args[0] instanceof Array) {
    res = mergeManyArrays(...args);
  } else {
    res = mergeManyObjects(...args);
  }

  return removeDisabledElements(res);
}
