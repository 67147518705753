import * as THREE from 'three'
import EvEmitter from './EvEmitter'

export default class Ray extends EvEmitter {
	constructor(ap3) {
		super()
		this.init(ap3)
	}

	init(ap3) {
		this.ap3 = ap3
		this.ray = new THREE.Raycaster()
		this.ray.near = 1
		this.ray.far = 11
		this.castList = []
		this.intersects = []
		this.curIntersect = null
	}

	tick() {
		this.ray.setFromCamera(this.ap3.pointer.pointer, this.ap3.cam.cam)
		this.intersects = this.ray.intersectObjects(this.castList)
		if(this.intersects.length && this.intersects[0].object != this.curIntersect) {
			this.curIntersect = this.intersects[0].object
			if(!this.ap3.pointer.dragging) { document.querySelector(`.spaces-scene-wrapper`).style.cursor = `pointer` }
			this.ap3.sphere.fade(this.curIntersect, 'in')
		}
		else if(!this.intersects.length && this.curIntersect) {
			if(!this.ap3.pointer.dragging) { document.querySelector(`.spaces-scene-wrapper`).style.cursor = `grab` }
			this.ap3.sphere.fade(this.curIntersect, 'out')
			this.curIntersect = null
		}
	}
	// document.style.pointer
	// document.style.grab


	trig() { switch (this.curIntersect) {
			case this.ap3.sphere.diy1_zone1 : this.trigger('clickProduct', ['advanced-level-360']) ; break
			case this.ap3.sphere.diy1_zone2 : this.trigger('clickProduct', ['atino']) ; break
			case this.ap3.sphere.diy1_zone3 : this.trigger('clickProduct', ['plr-50-c']) ; break
			case this.ap3.sphere.diy1_zone4 : this.trigger('clickProduct', ['truvo']) ; break
			case this.ap3.sphere.diy1_zone5 : this.trigger('clickProduct', ['universal-humid']) ; break
			case this.ap3.sphere.diy1_zone6 : this.trigger('clickProduct', ['zamo']) ; break
			case this.ap3.sphere.diy2_zone1 : this.trigger('clickProduct', ['universal-inspect']) ; break
			case this.ap3.sphere.diy2_zone2 : this.trigger('clickProduct', ['universal-level-3']) ; break
			case this.ap3.sphere.diy2_zone3 : this.trigger('clickProduct', ['detecteur-ptd']) ; break
			case this.ap3.sphere.diy2_zone4 : this.trigger('clickProduct', ['universal-level-360']) ; break
			case this.ap3.sphere.diy2_zone5 : this.trigger('clickProduct', ['quigo-green']) ; break
			case this.ap3.sphere.pro1_zone1 : this.trigger('clickProduct', ['gis-1000-c']) ; break
			case this.ap3.sphere.pro1_zone2 : this.trigger('clickProduct', ['glm-50-27-cg']) ; break
			case this.ap3.sphere.pro1_zone3 : this.trigger('clickProduct', ['glm-150-27-c']) ; break
			case this.ap3.sphere.pro1_zone4 : this.trigger('clickProduct', ['gic-120-c']) ; break
			case this.ap3.sphere.pro1_zone5 : this.trigger('clickProduct', ['gtc-600-c']) ; break
			case this.ap3.sphere.pro1_zone6 : this.trigger('clickProduct', ['gpl-5-g']) ; break
			case this.ap3.sphere.pro1_zone7 : this.trigger('clickProduct', ['d-tect-200-c']) ; break
			case this.ap3.sphere.pro2_zone1 : this.trigger('clickProduct', ['gcl-2-50-cg']) ; break
			case this.ap3.sphere.pro2_zone2 : this.trigger('clickProduct', ['gll-2-15-g']) ; break
			case this.ap3.sphere.pro2_zone3 : this.trigger('clickProduct', ['grl-400-h']) ; break
			case this.ap3.sphere.pro2_zone4 : this.trigger('clickProduct', ['grl-650-chvg']) ; break
			case this.ap3.sphere.pro2_zone5 : this.trigger('clickProduct', ['gll-3-80-cg']) ; break
			case this.ap3.sphere.diy1_arrow : this.trigger('clickArrow', ['diy2']) ; break
			case this.ap3.sphere.diy2_arrow : this.trigger('clickArrow', ['diy1']) ; break
			case this.ap3.sphere.pro1_arrow : this.trigger('clickArrow', ['pro2']) ; break
			case this.ap3.sphere.pro2_arrow : this.trigger('clickArrow', ['pro1']) ; break
			default : break
		}
	}
}