<template>
  <div id="spaces-visit" :class="{ visible }">
    <spaces-video
      :video="currentSpacesVideo"
      :visible="isSpacesVideoVisible"
      :controls="true"
      :muted="muted"
      @close="closeSpacesVideo"
    />
    <spaces-scene
      :scene="currentScene"
      :scenes-config="scenes"
      :visible="isSceneVisible"
      :do-tick="isSceneTicking"
      :hotspots-visible="areHotspotsVisible"
      :muted="muted || isSceneMuted"
      :gyro-enabled="gyroEnabled"
      :render="render"
      :gyro-datas="gyroDatas"
      @scene-mounted="sceneMounted(currentScene)"
      @open-product="openProductCard"
      @change-scene="changeScene"
      v-if="currentScene"
    />
    <product-card
      :visible="isProductCardOpened"
      :current-product="currentProduct"
      :config="productsConfig"
      :context="currentExperience"
      @play-video="openSpacesVideo"
      @close="closeProductCard"
      v-if="currentProduct !== null"
    />
    <div
      class="swipe-tutorial"
      :class="{
        hidden: !$breakpoints.isMobile || !tutorialVisible
      }"
      @click.prevent="hideTutorial"
    >
      <img class="tutorial-image" :src="require(`@/assets/icons/tutorial_icon.svg`)" alt="Swipe to discover" />
      <div class="tutorial-message">Faites défiler<br/>pour en voir plus</div>
    </div>
  </div>
</template>

<script>
import SpacesScene from '@/components/SpacesScene';
import ProductCard from '@/components/ProductCard';
import SpacesVideo from '@/components/SpacesVideo';

export default {
  components: {
    SpacesScene,
    ProductCard,
    SpacesVideo,
  },

  props: {
    visible: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    muted: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    render: {
      type: Boolean,
      required: false,
      default: () => true,
    },
    visit: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    currentExperience: {
      type: String,
      required: true,
    },
    lang: {
      type: Object,
      required: false,
      default: () => null,
    },
    gyroEnabled: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    gyroDatas: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },

  data: () => ({
    previousDialogName: null,
    currentDialog: null,
    isDialogVisible: false,
    currentSpacesVideo: null,
    isSpacesVideoVisible: false,
    firstSceneName: null,
    isChangingScene: false,
    previousSceneName: null,
    currentSceneName: null,
    isSceneVisible: false,
    isSceneTicking: false,
    areHotspotsVisible: false,
    isSceneMuted: false,
    isProductCardOpened: false,
    currentProduct: null,
    tutorialVisible: false,
    firstTimeMounted: true,
  }),

  computed: {
    scenes() {
      return this.visit?.scenes || [];
    },
    dialogs() {
      return this.visit?.dialogs || [];
    },
    map() {
      return this.visit?.map || [];
    },
    menu() {
      return this.visit?.menu || [];
    },
    tutorials() {
      return this.visit?.tutorials || [];
    },
    products() {
      return this.visit?.products || [];
    },
    shoePresentationConfig() {
      return this.visit?.shoePresentation || { '500': [], '500K': [] };
    },
    productsConfig() {
      return this.visit?.products[this.currentExperience] || [];
    },
    currentScene() {
      let currentScene = null;
      if (this.currentSceneName) {
        currentScene = this.getSceneByName(this.currentSceneName);
      }
      return currentScene || null;
    },
    isCurrentSceneHub() {
      return this.currentScene && this.currentScene.name === `hub`;
    },
    previousScene() {
      let previousScene = null;
      if (this.previousSceneName) {
        previousScene = this.getSceneByName(this.previousSceneName);
      }

      return previousScene || null;
    },
  },

  methods: {
    async wait(ms) {
      return new Promise(resolve => {
        setTimeout(() => resolve(), ms);
      });
    },
    hideTutorial() {
      this.tutorialVisible = false;
    },
    getDialogByName(dialogName) {
      return this.dialogs.find(dialog => dialog.name === dialogName);
    },
    getSceneByName(sceneName) {
      return this.scenes.find(scene => scene.name === sceneName);
    },
    getTutorialByName(tutorialName) {
      return this.tutorials.find(tuto => tuto.name === tutorialName);
    },
    openSpacesVideo(video) {
      this.currentSpacesVideo = video;
      this.isSpacesVideoVisible = true;
      this.$emit(`pause-sound`);
    },
    closeSpacesVideo() {
      this.isSpacesVideoVisible = false;
      this.currentSpacesVideo = null;
      this.$emit(`resume-sound`);
    },
    openProductCard(product) {
      this.currentProduct = product;
      this.isProductCardOpened = true;
    },
    closeProductCard() {
      this.isProductCardOpened = false;
    },
    resetZoom() {
      document.body.style.transform = `scale(1)`;
      document.body.style.msTransform = `scale(1)`;
    },
    pauseSound() {
      this.$emit(`pause-sound`);
    },
    resumeSound() {
      this.$emit(`resume-sound`);
    },
    toggleTutorial() {
      this.isTutorialOpened = !this.isTutorialOpened;
    },
    resumeSoundAfterDialogClose() {
      if (!this.currentScene.hasSound) {
        this.resumeSound();
      }
    },
    async changeScene(sceneName) {
      if (this.isChangingScene || this.currentSceneName === sceneName) { return; }

      await this.wait();
      this.isChangingScene = true;
      this.isSceneTicking = false;

      await this.wait(100);
      this.currentSceneName = sceneName;
    },
    async sceneMounted(scene) {
      if (this.previousScene) {
        this.previousScene.isVisible = false;
        setTimeout(() => {
          this.previousScene.isMounted = false;
        }, 500)
      }
      scene.isVisible = true;
      this.isChangingScene = false;
      await this.wait();
      this.isSceneTicking = true;
      this.isSceneVisible = true;
      this.areHotspotsVisible = true;
      this.$emit(`close-splashscreen`);
      if (this.firstTimeMounted) {
        this.tutorialVisible = true;
        setTimeout(() => {
          this.hideTutorial();
        }, 3000);
        this.firstTimeMounted = false;
      }
    },
    openHub() {
      this.changeScene(`hub`);
    },
    openMap() {
      this.isDialogVisible = false;
      this.isMapOpened = true;
    },
    closeMap() {
      this.isMapOpened = false;
    },
    goHome() {
      this.closeMap();
      if (this.currentSceneName !== this.firstSceneName) {
        this.changeScene(this.firstSceneName);
      }
    },
    async openDialog(dialogName) {
      const dialog = this.getDialogByName(dialogName);
      this.currentDialog = dialog;
      await this.wait();
      if (this.currentDialog.video) {
        this.isSceneMuted = true;
      }
      this.isDialogVisible = true;
    },
    async closeDialog() {
      this.isDialogVisible = false;
      this.isSceneMuted = false;
      if (this.previousDialogName) {
        await this.wait();
        this.openDialog(this.previousDialogName);
      }
      this.previousDialogName = null;
    },
  },

  mounted() {
    this.firstSceneName = `${this.currentExperience}1`;
    setTimeout(() => {
      this.changeScene(this.firstSceneName);
      if (!this.currentDialog) {
        this.currentDialog = this.dialogs[0];
      }
    });
  },

  watch: {
    currentExperience(newExp) {
      this.currentProduct = null;
      this.isProductCardOpened = false;
      this.changeScene(`${newExp}1`);
    },
    isProductCardOpened(open) {
      this.isSceneTicking = !open;
    }
  },
};
</script>

<style lang="scss" scoped>
@mixin animate($animation, $duration, $method, $times) {
  animation: $animation $duration $method $times;
}

@mixin keyframes($name) {
  @keyframes #{$name} {
    @content;
  }
}

.spaces-visit {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.lose-message {
  width: fit-content;
  position: absolute;
  transform: translate(-50%, 0);
  padding: 10px 30px;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 30px;
  color: #fff;
  font-size: 16px;
  opacity: 0;
  pointer-events: none;
  transition: opacity .4s;

  &.visible {
    opacity: 1;
  }
}

.swipe-tutorial {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: opacity 0.3s ease-in-out;

  &.hidden {
    opacity: 0;
    pointer-events: none;
  }

  .tutorial-image {
    width: 20%;

    @include keyframes(swipe) {
      0% {
        transform: translateX(50%);
      }
      50% {
        transform: translateX(-50%);
      }
      100% {
        transform: translateX(50%);
      }
    }
    @include animate(swipe, 2.5s, ease, infinite);
  }

  .tutorial-message {
    color: #fff;
  }
}

.csr-video-dialog {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: opacity 0.5s ease-in-out;
  opacity: 0;
  pointer-events: none;
  &.visible {
    opacity: 1;
    pointer-events: all;
  }
}
</style>
