<template>
  <div
    class="spaces-scene-wrapper"
    :class="{
      visible,
      mobile: $breakpoints.isMobile,
    }"
  >
    <canvas id="spaces-scene-first" class="scene-container" :class="{visible: currentSceneVisible === 0}"></canvas>
    <canvas id="spaces-scene-second" class="scene-container" :class="{visible: currentSceneVisible === 1}"></canvas>
  </div>
</template>

<script>
import Spaces from '@/Spaces';

Number.prototype.map = function (in_min, in_max, out_min, out_max) {
  return (this - in_min) * (out_max - out_min) / (in_max - in_min) + out_min;
}

let spacesApp;
const spacesApps = [];

export default {
  components: {
  },

  props: {
    scene: {
      type: Object,
      required: true,
    },
    scenesConfig: {
      type: Array,
      required: true,
    },
    visible: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    doTick: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    hotspotsVisible: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    muted: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    render: {
      type: Boolean,
      required: false,
      default: () => true,
    },
    gyroEnabled: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    gyroDatas: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },

  data: () => ({
    dialog: false,
    isOpeningDialog: false,
    isAnimateEnabled: false,
    isGoingToScene: false,
    isMouseDown: false,
    isUserInteracting: false,
    currentSceneI: 1,
    currentSceneVisible: 1,
  }),

  computed: {
    dialogHotspots() {
      return this.scene.dialogHotspots;
    },
    hubHotspots() {
      return this.scene.hubHotspots;
    },
    sceneHotspots() {
      return this.scene.sceneHotspots;
    },
    firstSceneContainer() {
      return document.getElementById(`spaces-scene-first`);
    },
    secondSceneContainer() {
      return document.getElementById(`spaces-scene-second`);
    },
  },

  methods: {
    async wait(ms) {
      return new Promise(resolve => {
        setTimeout(() => resolve(), ms);
      });
    },
    mainSphereZoneClicked(action, target) {
      // this.playSoundEffect();

      switch (action) {
        case `clickProduct`:
          this.$emit(`open-product`, target);
          break;
        case `clickArrow`:
          this.$emit(`change-scene`, target);
          break;
        default:
          break;
      }
    },
    async createCurrentScene() {
      const containerToMount = this.currentSceneI === 0 ? this.firstSceneContainer : this.secondSceneContainer;

      const tmpSpacesApp = new Spaces(containerToMount);
      const sphereHandler = {};
      const assets = [
        // GLBs
        { name: 'sphere', type: 'glb', url: require(`@/assets/glbs/sphere.glb`) },
        // Texs
        { name: 'arrow', type: 'tex', url: require(`@/assets/texs/arrow.png`), flip: true },
        { name: 'glow', type: 'tex', url: require(`@/assets/texs/glow.png`), flip: true },
        // Mats
        { name: 'arrow', type: 'matBas', map: 'arrow', transparent: true },
        { name: 'glow', type: 'matBas', map: 'glow', transparent: true },
      ];

      for (let scene of this.scenesConfig) {
        assets.push({ name: scene.name, type: 'tex', url: scene.tex });
        assets.push({ name: scene.name, type: 'matBas', map: scene.name });
      }

      await tmpSpacesApp.addAssets(assets, sphereHandler);
      tmpSpacesApp.setSphere(sphereHandler);

      tmpSpacesApp.ray.on(`clickProduct`, (product) => { this.mainSphereZoneClicked(`clickProduct`, product) });
      tmpSpacesApp.ray.on(`clickArrow`,   (scene)   => { this.mainSphereZoneClicked(`clickArrow`, scene)   });

      spacesApps[this.currentSceneI] = tmpSpacesApp;
    },
    async changeScene() {
      this.currentSceneI = (this.currentSceneI + 1) % 2;
      if (!spacesApps[this.currentSceneI]) {
        await this.createCurrentScene();
      }

      spacesApps[this.currentSceneI].sphere.setLocation(this.scene.name);
      spacesApp = spacesApps[this.currentSceneI];
      this.$emit(`scene-mounted`);
    },
    startSceneTransition() {
        const toFadeIn = this.currentSceneI === 0 ? this.firstSceneContainer : this.secondSceneContainer;
        const toFadeOut = this.currentSceneI === 1 ? this.firstSceneContainer : this.secondSceneContainer;

        toFadeIn.style.transition = `opacity 0.5s ease-in-out`;
        toFadeOut.style.transition = `opacity 0.8s ease-in-out`;
        this.currentSceneVisible = this.currentSceneI;
    },
    openVideo(videoName) {
      this.$emit(`open-video`, videoName);
    },
    openShoe() {
      this.$emit(`open-shoe`);
    },
    openGame() {
      this.$emit(`open-game`);
    },
    closeGame() {
      this.$emit(`close-game`);
    },
  },

  async mounted() {
    this.changeScene();
  },

  watch: {
    doTick(ticking) {
      if (ticking) {
        spacesApp.time.startTicking();
        this.startSceneTransition();
      } else {
        spacesApp.time.stopTicking();
      }
    },
    visible(visible) {
      if (visible && this.doTick) {
        spacesApp.time.startTicking();
      } else {
        spacesApp.time.stopTicking();
      }
    },
    scene() {
      this.changeScene();
    }
  },
};
</script>

<style lang="scss" scoped>
$genericHotspotSizeMobile: 25px;
$genericHotspotSizeDesktop: 35px;
$imgHotspotSize: 40px;

.spaces-scene-wrapper {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  cursor: grab;
  transition: opacity 0.5s ease-in-out;
  opacity: 0;
  &.visible {
    opacity: 1;
  }

  .close-button {
    position: absolute;
    top: 20px;
    left: 20px;
    width: 30px;
    background-color: transparent;
    border: none;
    padding: 0;
    -webkit-tap-highlight-color: transparent;

    img {
      width: 100%;
    }
  }

  .scene-container {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transition: opacity 0.5s ease-in-out;
    opacity: 0;
    pointer-events: none;

    &.visible {
      opacity: 1;
      pointer-events: all;
    }
  }

  .hotspots-container {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 95;
    pointer-events: none;
    transition: opacity 0.1s ease-in-out;
    opacity: 0;
    &.visible {
      opacity: 1;
    }
    * {
      pointer-events: all;
    }
  }

  .generic-hotspot,
  .dialog-hotspot,
  .scene-hotspot,
  .hub-hotspot {
    z-index: 95;
  }

  &.dragging {
    .generic-hotspot,
    .dialog-hotspot,
    .scene-hotspot,
    .hub-hotspot {
      pointer-events: none;
    }
  }

  .generic-hotspot {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    background-color: #B4001A;
    color: #FFF;
    height: $genericHotspotSizeMobile;
    width: $genericHotspotSizeMobile;
    border-radius: 50px;
    white-space: nowrap;
    border: 3px solid rgba(255, 255, 255, 0.3);
    -webkit-background-clip: padding-box;
    background-clip: padding-box;

    &.hub {
      height: $genericHotspotSizeDesktop * 1.1;
      width: $genericHotspotSizeDesktop * 1.1;

      .legend {
        font-weight: 300;
      }
    }
  }

  .hub-hotspot {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    background-color: #B4001A;
    color: #FFF;
    font-size: 11px;
    border-radius: 50px;
    white-space: nowrap;
    border: 5px solid rgba(255, 255, 255, 0.3);
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    padding: 6px 11px;
  }

  .dialog-hotspot,
  .scene-hotspot {
    position: absolute;
    width: $imgHotspotSize;
    height: $imgHotspotSize;
    top: -100px;
    left: -100px;
    margin-top: -($imgHotspotSize / 2);
    margin-left: -($imgHotspotSize / 2);
    cursor: pointer;
    z-index: 95;
  }

  .generic-hotspot,
  .dialog-hotspot,
  .scene-hotspot {
    cursor: pointer;

    .img-icon {
      height: $genericHotspotSizeMobile - 11px;
    }

    .hotspot-legend {
      position: absolute;
      top: $imgHotspotSize + 6px;
      left: 50%;
      transform: translateX(-50%);
      background-color: #FFF;
      font-size: 13px;
      border-radius: 3px;
      white-space: nowrap;
      border: 8px solid rgba(255, 255, 255, 0.3);
      -webkit-background-clip: padding-box;
      background-clip: padding-box;
      padding: 6px;
      color: #2c3e50;
      display: none;
      pointer-events: none;
    }

    &:hover {
      .hotspot-legend {
        display: block;
      }
    }
  }

  .scene-hotspot {
    cursor: default;

    img {
      cursor: pointer;
    }
  }

  .generic-hotspot,
  .dialog-hotspot,
  .scene-hotspot,
  .hub-hotspot {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

    .legend {
      pointer-events: none;
      cursor: default;
    }
  }

  &:not(.mobile) {
    .generic-hotspot {
      height: $genericHotspotSizeDesktop;
      width: $genericHotspotSizeDesktop;

      &:hover {
        border: 2px solid #FFF;
      }

      &.hub.has-legend {
        height: $genericHotspotSizeDesktop * 1.2;
        width: $genericHotspotSizeDesktop * 1.2;
        &:hover {
          width: auto;
          height: $genericHotspotSizeDesktop * 1.4;
          z-index: 100;

          .img-icon-wrapper {
            display: none;
          }
        }
        &:not(:hover) {
          .legend {
            display: none;
          }
        }
      }
    }
  }

  .scene-hotspot {
    .hotspot-legend {
      display: block;
    }
  }

  .generic-hotspot.exit-button {
    bottom: 30px;
    left: 50%;
    top: auto;
    right: auto;
    font-size: 28px;
    height: $genericHotspotSizeMobile * 2;
    width: $genericHotspotSizeMobile * 2;
  }
}

.dialog-hotspot img,
.scene-hotspot img {
  width: 100%;
  height: 100%;
}
</style>
