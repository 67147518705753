
export default [
  {
    url: require(`@/assets/fonts/BoschSans-Light.otf`),
    name: `BoschSans`,
    weight: 200,
  },
  {
    url: require(`@/assets/fonts/BoschSans-Regular.otf`),
    name: `BoschSans`,
    weight: 400,
  },
  {
    url: require(`@/assets/fonts/BoschSans-Medium.otf`),
    name: `BoschSans`,
    weight: 500,
  },
  {
    url: require(`@/assets/fonts/BoschSans-Bold.otf`),
    name: `BoschSans`,
    weight: 700,
  },
  {
    url: require(`@/assets/fonts/BoschSans-Black.otf`),
    name: `BoschSans`,
    weight: 900,
  },
  {
    url: require(`@/assets/fonts/BoschSans-LightItalic.otf`),
    name: `BoschSansItalic`,
    weight: 200,
  },
  {
    url: require(`@/assets/fonts/BoschSans-RegularItalic.otf`),
    name: `BoschSansItalic`,
    weight: 400,
  },
  {
    url: require(`@/assets/fonts/BoschSans-MediumItalic.otf`),
    name: `BoschSansItalic`,
    weight: 500,
  },
  {
    url: require(`@/assets/fonts/BoschSans-BoldItalic.otf`),
    name: `BoschSansItalic`,
    weight: 700,
  },
  {
    url: require(`@/assets/fonts/BoschSans-BlackItalic.otf`),
    name: `BoschSansItalic`,
    weight: 900,
  },
];
