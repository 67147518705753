<template>
  <div
    class="splash-screen"
    :class="{
      visible,
      mobile: $breakpoints.isMobile,
    }"
  >
    <div class="splash-section diy-section">
      <img :src="require(`@/assets/splashscreen/logo_white.png`)" alt="bosch-logo" />
      <p v-if="$breakpoints.isMobile">ALLEZ VERS</p>
      <p v-else>ENTREZ DANS L'UNIVERS</p>
      <div class="spacer"></div>
      <h2>DIY</h2>
      <button @click="startExperience(`diy`)">Commencez<img :src="require(`@/assets/icons/arrow_right.svg`)" alt="Start"></button>
    </div>
    <div class="splash-section pro-section">
      <img :src="require(`@/assets/splashscreen/logo_white.png`)" alt="bosch-logo" />
      <p v-if="$breakpoints.isMobile">ALLEZ VERS</p>
      <p v-else>ENTREZ DANS L'UNIVERS</p>
      <div class="spacer"></div>
      <h2>PRO</h2>
      <button @click="startExperience(`pro`)">Commencez<img :src="require(`@/assets/icons/arrow_right.svg`)" alt="Start"></button>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    visible: {
      type: Boolean,
      required: false,
      default: () => false,
    },
  },

  methods: {
    startExperience(exp) {
      this.$emit(`start-experience`, exp);
    }
  },
};
</script>

<style lang="scss" scoped>
.splash-screen {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: opacity 0.5s ease-in-out;
  opacity: 0;
  pointer-events: none;
  display: flex;
  align-items: flex-end;
  justify-content: center;

  &.visible {
    opacity: 1;
    pointer-events: all;
  }

  .splash-section {
    width: 50%;
    height: calc(100% - 80px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    color: #ffffff;

    img {
      width: 50px;
    }

    p {
      font-size: 18px;
      font-weight: 700;
    }

    .spacer {
      width: 10%;
      height: 7px;
    }

    h2 {
      font-size: 148px;
      margin: 0.1em 0;
      font-weight: 900;
    }

    button {
      padding: 1em 0;
      width: calc(100% * 2 / 5);
      margin-top: 3em;
      color: #ffffff;
      font-size: 20px;
      font-weight: 700;
      font-family: BoschSans, Avenir, Helvetica, Arial, sans-serif;
      border: none;
      cursor: pointer;

      transition: background-color 0.5s ease-in-out, color 0.5s ease-in-out;

      &:focus {
        outline: none;
      }

      img {
        display: none;
      }
    }

    &.diy-section {
      background-image: url(@/assets/splashscreen/diy.jpeg);

      .spacer {
        background-color: #006C3A;
      }

      button {
        background-color: #006C3A;

        &:hover {
          background-color: #ffffff;
          color: #006C3A;
        }
      }
    }

    &.pro-section {
      background-image: url(@/assets/splashscreen/pro.jpeg);

      .spacer {
        background-color: #013b6a;
      }

      button {
        background-color: #013b6a;

        &:hover {
          background-color: #ffffff;
          color: #013b6a;
        }
      }
    }
  }

  &.mobile {
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;

    .splash-section {
      height: calc((100% - 75px) / 2);
      // height: calc(100% - 75px);
      width: 100%;

      img {
        width: 25px;
      }

      p {
        font-size: 14px;
        font-weight: 500;
        margin: 0.5em 0 1em;
      }

      .spacer {
        height: 5px;
        width: 12%;
      }

      h2 {
        font-size: 72px;
        margin: 2vh 0;
      }

      button {
        margin: 0;
        font-size: 16px;
        font-weight: 400;
        padding: 0.7em 1em;
        width: auto;
        display: flex;
        align-items: center;

        img {
          width: 40px;
          margin: 0 0.2em 0 1em;
          display: inline-block;
        }

        // margin-top: 1em;
      }
    }
  }
}
</style>
