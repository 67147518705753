export default [
  {
    name: `glm-50-27-cg`,
    video: require(`@/assets/products/PRO/GLM_50_27_CG/GLM_50_27_CG.mp4`),
    images: [
      {
        name: `image-1`,
        src: require(`@/assets/products/PRO/GLM_50_27_CG/1.jpeg`),
      },
      {
        name: `image-2`,
        src: require(`@/assets/products/PRO/GLM_50_27_CG/2.jpeg`),
      },
      {
        name: `image-3`,
        src: require(`@/assets/products/PRO/GLM_50_27_CG/3.jpeg`),
      },
      {
        name: `image-4`,
        src: require(`@/assets/products/PRO/GLM_50_27_CG/4.jpeg`),
      },
    ],
    productName: `GLM 50-27 CG`,
    productReference: `0.601.072.U00`,
    mainSection: {
      name: `main`,
      title: `Télémètre laser d’intérieur robuste et connecté avec laser vert pour une excellente visibilité.`,
      text: `Grâce à sa portée de 50m et à son laser vert, le GLM 50-27 CG peut prendre des mesures longues distances dans des environnements très lumineux. Le design robuste a été conçu pour résister aux chantiers les plus rudes avec une indice de protection IP 65. Le boitier caoutchouté et renforcé absorbe les chocs et les chutes d’une hauteur de 1,5 m. La connexion Bluetooth facilite les mesures à distance et permet un transfert rapide des données avec l’application Bosch MesureOn.`,
    },
    subSections: [
      {
        name: `grande-portee`,
        title: `Grande portée`,
        texts: [
          `Le GLM 50-27 CG peut effectuer des mesures jusqu’à 50m. Son Laser vert lui confère une excellente visibilité, même dans les environnements très lumineux`,
        ],
      },
      {
        name: `mesure-continue`,
        title: `Mesure Continue – mode gros chiffres`,
        texts: [
          `Ce mode permet à l’utilisateur d’avoir une lisibilité rapide et facile de la mesure. Seule la mesure effectuée est affichée à l’écran, estompant les autres informations.`,
        ],
      },
      {
        name: `repetition-distance`,
        title: `Répétition distance`,
        texts: [
          `Cette fonction permet de mesurer en répétition une longueur prédéfini. La distance sera ainsi répétée tous les X mètres et vous permet d’effectuer des marquages de manière précise.`,
        ],
      },
      {
        name: `feedback`,
        title: `Feedback audio et par vibration`,
        texts: [
          `Le télémètre émet un son et des vibrations afin de confirmer la prise de mesure.`,
        ],
      },
    ],
  },
  {
    name: `glm-150-27-c`,
    video: require(`@/assets/products/PRO/GLM_150_27_C/GLM_150_27_C.mp4`), // remplacer par le chemin de la vidéo associée au produit
    images: [
      {
        name: `image-1`,
        src: require(`@/assets/products/PRO/GLM_150_27_C/1.jpeg`), // remplacer par le chemin de la première image, idem pour les images 2, 3, 4 ... x
      },
      {
        name: `image-2`,
        src: require(`@/assets/products/PRO/GLM_150_27_C/2.jpeg`),
      },
      {
        name: `image-3`,
        src: require(`@/assets/products/PRO/GLM_150_27_C/3.jpeg`),
      },
      {
        name: `image-4`,
        src: require(`@/assets/products/PRO/GLM_150_27_C/4.jpeg`),
      },
    ],
    productName: `GLM 150-27 C`,
    productReference: `0.601.072.Z00`,
    mainSection: {
      name: `main`,
      title: `Télémètre connecté d’extérieur, pour des mesures simplifiées même sur de grandes distances.`,
      text: `Le GLM 150-27 C est résistant aux conditions les plus rudes des chantier grâce à son design robuste et son un indice de protection IP 54. L’appareil peut prendre des mesures jusqu’à 150 m évitant ainsi les déplacements. Le grand écran couleur offre une lisibilité optimale. C’est le choix incontournable des architectes, des électriciens et de nombreuses autres professions pour gagner du temps grâce à une utilisation facile et intuitive. La connectivité Bluetooth et l’application Bosch MesureOn rendent le transfert de documentation et de données simple et rapide.`
    },
    subSections: [
      {
        name: `longue-portee`,
        title: `Longue portée`,
        texts: [
          `Le GLM 150-27 C peut effectuer des mesures jusqu’à 150m. Il est idéal pour les travaux d’extérieurs lorsque les mesures sur de grandes distances sont nécessaires ou bien lorsque vous souhaitez.`,
        ],
      },
      {
        name: `viseur-photo`,
        title: `Viseur Photo`,
        texts: [
          `Grâce à son module photo, il est possible de viser directement et précisément l’endroit souhaité et ce, même dans des conditions de forte luminosité.`,
        ],
      },
      {
        name: `zoom`,
        title: `Zoom`,
        texts: [
          `La fonction ZOOM vous permet d’établir précisément l’endroit visé pour les mesures lointaines. Cela évite à l’utilisateur de se déplacer et permet d’atteindre des zones inaccessibles.`,
        ],
      },
      {
        name: `grand-ecran-couleur`,
        title: `Grand écran couleur`,
        texts: [
          `Le GLM 150-27 C dispose d’un grand écran couleur, facilitant la visibilité ainsi que la lecture et l’interprétation des mesures.`,
        ],
      },
    ],
  },
  {
    name: `gpl-5-g`,
    video: require(`@/assets/products/PRO/GPL_5_G/GPL_5_G.mp4`),
    images: [
      {
        name: `image-1`,
        src: require(`@/assets/products/PRO/GPL_5_G/1.jpeg`),
      },
      {
        name: `image-2`,
        src: require(`@/assets/products/PRO/GPL_5_G/2.jpeg`),
      },
      {
        name: `image-3`,
        src: require(`@/assets/products/PRO/GPL_5_G/3.jpeg`),
      },
      {
        name: `image-4`,
        src: require(`@/assets/products/PRO/GPL_5_G/4.jpeg`),
      },
    ],
    productName: `GPL 5 G`,
    productReference: `0.601.066.P00`,
    mainSection: {
      name: `main`,
      title: `Laser point robuste et compact avec laser vert`,
      text: `Le laser point GPL 5 G est doté de 5 points laser verts pour une meilleure visibilité. Le boitier caoutchouté et ainsi que l’indice de protection IP 65 permet au laser d’être résistant pour s’adapter aux environnements les plus rudes. Le système pendulaire amélioré est idéal pour les poses de cloisons à 90°, les reports de points de sol vers le plafond, des reports de hauteurs ainsi que les travaux de plomberie.`
    },
    subSections: [
      {
        name: `support-rotatif-integre`,
        title: `Support rotatif intégré`,
        texts: [
          `Le support est doté d’aimants très puissants permettant de fixer le laser à différentes hauteurs sur des surfaces magnétiques.`,
        ],
      },
      {
        name: `transfert-point-aplomb`,
        title: `Transfert du point d’aplomb`,
        texts: [
          `La fonction permet de simplifier l’utilisation de l’appareil pour les transferts de points de sols et de plafonds (fil à plomb).`,
        ],
      },
      {
        name: `point-horizontal`,
        title: `Point horizontal`,
        texts: [
          `La fonction facilite les reports de hauteurs et des équerrages. Elle est utile pour les charpentiers, les électriciens et les plombiers …`,
        ],
      },
      {
        name: `protection`,
        title: `Protection`,
        texts: [
          `La coque de protection en caoutchouc délivre une meilleure protection contre les chocs et un positionnement plus stable au sol. Ainsi, le laser point est adaptée aux environnements exigeants.`,
        ],
      },
    ],
  },
  {
    name: `gll-2-15-g`,
    video: require(`@/assets/products/PRO/GLL_2_15_G/GLL_2_15_G.mp4`),
    images: [
      {
        name: `image-1`,
        src: require(`@/assets/products/PRO/GLL_2_15_G/1.jpeg`),
      },
      {
        name: `image-2`,
        src: require(`@/assets/products/PRO/GLL_2_15_G/2.jpeg`),
      },
      {
        name: `image-3`,
        src: require(`@/assets/products/PRO/GLL_2_15_G/3.jpeg`),
      },
      {
        name: `image-4`,
        src: require(`@/assets/products/PRO/GLL_2_15_G/4.jpeg`),
      },
    ],
    productName: `GLL 2-15 G`,
    productReference: `0.601.063.W00`,
    mainSection: {
      name: `main`,
      title: `Laser lignes robuste et simple d’utilisation`,
      text: `Le faisceau laser vert offre une meilleure visibilité des lignes laser horizontale et verticale avec une portée maximale de 15 m. Le boitier est renforcé par du caoutchouc et certifié IP 64 contre la poussière et les projections d’eau IP 64 garantissant une grande robustesse pour les environnements rudes. Le support LB 10 avec aimants intégrés se fixe aisément sur les surfaces métalliques et assure une fixation rapide du laser.`
    },
    subSections: [
      {
        name: `nivellement-horizontal`,
        title: `Nivellement horizontal`,
        texts: [
          `La fonction permet de monter et de poser des étagères et des armoires. Elle est aussi utilisée pour les installations de fenêtres, les poses de tuyaux d’évacuation, …. Ce mode facilite la prise de mesures et de notes des charpentiers, des menuisiers.`,
        ],
      },
      {
        name: `nivellement-vertical`,
        title: `Nivellement vertical`,
        texts: [
          `La fonction facilite le montage des portes et des fenêtres. Elle permet aussi de prendre des mesures pour accrocher des meubles.`,
        ],
      },
      {
        name: `lignes-croisees`,
        title: `Lignes croisées`,
        texts: [
          `Le croisement des lasers vert pour une mise à niveau horizontale et verticale délivre une haute visibilité. Le mise à niveau permet d’utiliser l’appareil dans plusieurs situations comme la pose de cloisons sèches.`,
        ],
      },
      {
        name: `mode-pente`,
        title: `Mode pente`,
        texts: [
          `Combiné au trépied BT 150 il est possible d’incliner le laser pour le mettre dans une position de pente afin de permettre l’installation de rampes ou de conduites inclinées.`,
        ],
      },
    ],
  },
  {
    name: `gcl-2-50-cg`,
    video: require(`@/assets/products/PRO/GCL_2_50_CG/GCL_2_50_CG.mp4`),
    images: [
      {
        name: `image-1`,
        src: require(`@/assets/products/PRO/GCL_2_50_CG/1.jpeg`),
      },
      {
        name: `image-2`,
        src: require(`@/assets/products/PRO/GCL_2_50_CG/2.jpeg`),
      },
      {
        name: `image-3`,
        src: require(`@/assets/products/PRO/GCL_2_50_CG/3.jpeg`),
      },
      {
        name: `image-4`,
        src: require(`@/assets/products/PRO/GCL_2_50_CG/4.jpeg`),
      },
    ],
    productName: `GCL 2-50 CG`,
    productReference: `0.601.066.H00`,
    mainSection: {
      name: `main`,
      title: `Laser combiné connecté avec laser vert`,
      text: `Le laser combiné GCL 2-50 CG offre une visibilité maximale grâce à ses deux lignes horizontale et verticale à faisceau vert et ses deux points d’aplomb verticaux. L’appareil fonctionne jusqu’à 20 m de distance sans cellule de réception et jusqu’à 50 m avec une cellule de réception (LR6 ou LR7). Pour un positionnement manuel précis autour d’un point d’aplomb, il est possible d’utiliser le support rotatif RM2. La connexion Bluetooth permet un pilotage à distance via l’application Bosch Levelling Remote. Il est aussi possible de contrôler à distance le support rotatif RM3 pour obtenir un positionnement précis. Le laser combiné est conçu pour effectuer des alignements horizontaux et verticaux sur 360° notamment lors des poses de cloisons sèches et les travaux de finition intérieure.`
    },
    subSections: [
      {
        name: `laser-combine`,
        title: `Laser combiné`,
        texts: [
          `Le GCL 2-50 CG dispose des atouts d’un laser lignes et d’un laser points avec deux lignes laser à faisceau vert et deux points d’aplomb verticaux. Cela le rend extrêmement polyvalent sur le chantier.`,
        ],
      },
      {
        name: `connectivite`,
        title: `Connectivité`,
        texts: [
          `Le GCL 2-50 CG dispose d’une connexion Bluetooth lui permettant de communiquer et d’être piloté par l’application Bosch Levelling Remote.`,
        ],
      },
      {
        name: `blocage-systeme-pendulaire`,
        title: `Blocage du système pendulaire`,
        texts: [
          `Le verrouillage du pendule (lorsque le laser est penché sur le côté) permet d’effectuer des mesures en pente pour les installations de tuyaux, de rampes ou d’escaliers. Le pendule est verrouillé lorsque le laser combiné est éteint pour un transport en toute sécurité.`,
        ],
      },
      {
        name: `laser-vert`,
        title: `Laser vert`,
        texts: [
          `Les lignes laser vert ont une visibilité jusqu’à 4 fois supérieure au laser rouge. Cela permet de voir clairement les faisceaux laser, même dans les environnements les plus lumineux`,
        ],
      },
    ],
  },
  {
    name: `gll-3-80-cg`,
    video: require(`@/assets/products/PRO/GLL_3_80_CG/GLL_3_80_CG.mp4`),
    images: [
      {
        name: `image-1`,
        src: require(`@/assets/products/PRO/GLL_3_80_CG/1.jpeg`),
      },
      {
        name: `image-2`,
        src: require(`@/assets/products/PRO/GLL_3_80_CG/2.jpeg`),
      },
      {
        name: `image-3`,
        src: require(`@/assets/products/PRO/GLL_3_80_CG/3.jpeg`),
      },
      {
        name: `image-4`,
        src: require(`@/assets/products/PRO/GLL_3_80_CG/4.jpeg`),
      },
    ],
    productName: `GLL 3-80 CG`,
    productReference: `0.601.063.T00`,
    mainSection: {
      name: `main`,
      title: `Laser lignes connecté`,
      text: `Le laser GLL 3-80 CG propose une visibilité optimale avec ses 3 lignes verte à 360 °. Cela permet d’effectuer simultanément des mises à niveau horizontales et verticales pour un maximum d’efficacité grâce à ses 3 plans. L’appareil a une portée de 30 m et jusqu’à 120 m avec la cellule de réception LR7. Son design robuste et adapté à diverses situations grâce à l’indice de protection IP 54 contre la poussière et les projections d’eau. Dôté d’un connexion Bluetooth le GLL 3-80 peut être piloté à distance via l’application Bosch Levelling Remote.`
    },
    subSections: [
      {
        name: `3-plans-360`,
        title: `3 Plans à 360°`,
        texts: [
          `Les 3 plans permettent de faire tous les alignements et tous les équerrages avec un seul appareil. Cela permet ainsi d’utiliser l’appareil dans une multitude d’applications comme la pose de sol et de carrelages, l’installation de fenêtre, le montage d’étagères et de faux plafonds, la réalisation d’ossatures pour les cloisons sèches…`,
        ],
      },
      {
        name: `connectivite`,
        title: `Connectivité`,
        texts: [
          `L’application Bosch Levelling Remote permet de contrôler à distance le laser. L’application permet de changer les lignes et les modes mais aussi de piloter le support RM 3. L’utilisateur peut ajuster la visibilité des lignes laser afin de préserver l’autonomie de la batterie.`,
        ],
      },
      {
        name: `calguard`,
        title: `CALGuard`,
        texts: [
          `La fonction de surveillance CALGuard offre plus de fiabilité et de précision dans la réalisation des mesures. Cette fonction permet de détecter les chocs ou les changements brusques de températures subits par le laser et ainsi préserver la précision de l’appareil.`,
        ],
      },
      {
        name: `dualpower`,
        title: `Dual power`,
        texts: [
          `Le laser ligne peut fonctionner à la fois avec une batterie Lithium-Ion de 12 V ou avec des piles alcalines grâce à l’adaptateur Dual Power AA1.`,
        ],
      },
    ],
  },
  {
    name: `grl-650-chvg`,
    video: require(`@/assets/products/PRO/GRL_650_CHVG/GRL_650_CHVG.mp4`),
    images: [
      {
        name: `image-1`,
        src: require(`@/assets/products/PRO/GRL_650_CHVG/1.jpeg`),
      },
      {
        name: `image-2`,
        src: require(`@/assets/products/PRO/GRL_650_CHVG/2.jpeg`),
      },
      {
        name: `image-3`,
        src: require(`@/assets/products/PRO/GRL_650_CHVG/3.jpeg`),
      },
      {
        name: `image-4`,
        src: require(`@/assets/products/PRO/GRL_650_CHVG/4.jpeg`),
      },
    ],
    productName: `GRL 650 CHVG`,
    productReference: `0.601.061.V00`,
    mainSection: {
      name: `main`,
      title: `Laser rotatif connecté à laser vert pour les conditions difficiles.`,
      text: `Le laser rotatif GRL 650 CHVG offre une visibilité maximale et un niveau de précision élevé grâce à la technologie laser vert. L’appareil est robuste, il résiste aux conditions les plus difficiles et aux chutes jusqu’à 2m depuis son trépied grâce aux poignées innovantes et à l’indice de protection IP 68. La connectivité Bluetooth reliée à l’application Bosch Levelling Remote offre une vue d’ensemble de tous les réglages et permet de piloter le laser rotatif à distance afin de gagner du temps. La prise de mesure fonctionne avec la cellule de réception LR 65G dotée d’une grande zone de réception de 125mm. L’appareil dispose d’une grande autonomie grâce aux choix d’alimentation, soit une batterie Lithium-Ion Bosch de 18 V ou des piles alcalines LR 20 (alimentation Dual Power).`
    },
    subSections: [
      {
        name: `grande-portee`,
        title: `Grande portée`,
        texts: [
          `Le GRL 650 CHVG est parfaitement adaptés aux chantiers en extérieurs grâce à son faisceau laser vert très visible et sa grande portée de 650m de diamètre.`,
        ],
      },
      {
        name: `double-pente`,
        title: `Double pente`,
        texts: [
          `Le GRL 650 CHVG peut calculer des pentes simples sur deux axes ou bien des doubles pentes. Il est idéal pour le terrassement mais aussi la construction de parking avec écoulement des eaux vers un point unique.`,
        ],
      },
      {
        name: `centerlock`,
        title: `CenterLock`,
        texts: [
          `En mode CenterLock, le GRL 650 aligne le faisceau laser sur le centre du récepteur laser en déplaçant la tête de rotation de haut en bas, puis le verrouille dans cette position. Le faisceau laser va alors suivre le mouvement de la cellule. Cette fonction n’indique pas la mesure de la pente à l’écran.`,
        ],
      },
      {
        name: `system-anti-choc`,
        title: `Système Anti-Choc`,
        texts: [
          `L’ajout de deux nouveaux niveaux de sensibilités permet à l’utilisateur de s’adapter à son environnement et ainsi de paramétrer le laser de manière précise.`,
        ],
      },
    ],
  },
  {
    name: `grl-400-h`,
    images: [
      {
        name: `image-1`,
        src: require(`@/assets/products/PRO/GRL_400_H/1.jpeg`),
      },
      {
        name: `image-2`,
        src: require(`@/assets/products/PRO/GRL_400_H/2.jpeg`),
      },
      {
        name: `image-3`,
        src: require(`@/assets/products/PRO/GRL_400_H/3.jpeg`),
      },
      {
        name: `image-4`,
        src: require(`@/assets/products/PRO/GRL_400_H/4.jpeg`),
      },
    ],
    productName: `GRL 400 H`,
    productReference: `0.601.061.800`,
    mainSection: {
      name: `main`,
      title: `Laser rotatif adapté aux applications de mises à niveau en extérieur`,
      text: `Le laser rotatif GRL 400 H permet d’effectuer rapidement des mises à niveau horizontale. L’utilisation est simple et intuitive avec une commande par bouton unique. L’appareil est optimal pour effectuer des mesures en extérieur avec sa portée de 400 m de diamètre et la cellule de réception LR 1. Le laser rotatif peut supporter les conditions rudes des chantiers grâce à son boitier robuste et sa protection IP 56 contre la poussière et les projections d’eau. C’est donc le laser idéal pour le coulage d’une dalle, aplanir son terrain, aménager une façade et une terrasse…`
    },
    subSections: [
      {
        name: `grande-portee`,
        title: `Grande portée`,
        texts: [
          `Grâce à ses 400m de diamètre de portée, le GRL 400 H est parfaitement adapté aux travaux de nivellement en extérieur.`,
        ],
      },
      {
        name: `mise-niveau-automatique`,
        title: `Mise à niveau automatique`,
        texts: [
          `La mise à niveau automatique permet d’exclure toutes erreurs de mesure afin de garder la précision des mesures. Elle fonctionne en mode horizontal et sur une pente de moins de 5°. Cette fonction d’auto-nivellement permet à l’utilisateur de gagner du temps sur le chantier.`,
        ],
      },
      {
        name: `anti-choc`,
        title: `Anti-choc`,
        texts: [
          `La fonction anti-choc est un système de sécurité qui permet au laser rotatif de s’arrêter lorsqu’il subit un mouvement, une secousse et/ou un choc. Cela permet d’éviter des erreurs de précision des mesures.`,
        ],
      },
    ],
  },
  {
    name: `d-tect-200-c`,
    video: require(`@/assets/products/PRO/D_tect_200_C/D_tect_200_C.mp4`),
    images: [
      {
        name: `image-1`,
        src: require(`@/assets/products/PRO/D_tect_200_C/1.jpeg`),
      },
      {
        name: `image-2`,
        src: require(`@/assets/products/PRO/D_tect_200_C/2.jpeg`),
      },
      {
        name: `image-3`,
        src: require(`@/assets/products/PRO/D_tect_200_C/3.jpeg`),
      },
      {
        name: `image-4`,
        src: require(`@/assets/products/PRO/D_tect_200_C/4.jpeg`),
      },
    ],
    productName: `D-Tect 200 C`,
    productReference: `0.601.081.600`,
    mainSection: {
      name: `main`,
      title: `Scanner mural connecté garantissant une détection précise des objets quelle que soit la situation`,
      text: `Le D-tect 200 C détecte jusqu’à 20 cm de profondeur les objets courants, en métal, en bois et les tuyaux rempli d’eau. L’appareil dispose de trois modes de mesure : détection d’objets, détection de fuites, mesure de la distance. La technologie radar de Bosch garantit une précision dans toutes les situations offrant une visualisation claire des objets détectés. Les multiples vues permettent d’obtenir des résultats de détection clairs quelles que soient les conditions. Ne prenez plus de risques avant de percer.`
    },
    subSections: [
      {
        name: `differentes-vues`,
        title: `Différentes vues`,
        texts: [
          `Le D-tect 200 C dispose de quatre types de vues : vue objet, vue spot, vue signal 2D et vue signal. La vue objet offre une illustration de l’objet détecté grâce au code couleur et à l'indication de la profondeur. La vue spot facilite la détection dans les endroits difficiles d’accès. La vue signal 2D combine l'intensité du signal et l'indication du niveau de profondeur. La vue signal mesure la puissance du signal.`,
        ],
      },
      {
        name: `detection-fuites`,
        title: `Détection de fuites`,
        texts: [
          `Le D-tect 200 C offre une nouvelle application, la détection des fuites. La fonction va permettre de localiser rapidement le source de la fuite d’eau à l’intérieur du mur en mesurant l’hygrométrie de celui-ci.`,
        ],
      },
      {
        name: `capture-ecran`,
        title: `Capture d’écran`,
        texts: [
          `La capture d’écran permet de documenter rapidement et simplement les résultats. Les informations peuvent être facilement transmises via le câble USB-C et la carte SD.`,
        ],
      },
      {
        name: `type-mur`,
        title: `Type de mur`,
        texts: [
          `Afin de rendre la détection encore plus précise, il est nécessaire de bien paramétrer le type de mur sur lequel la détection est effectuée. Le D-tect 200 C peut détecter des objets sur les surfaces suivantes : Brique, béton, cloison sèche, chauffage par panneau, brique perforée verticalement et horizontalement, béton jeune.`,
        ],
      },
    ],
  },
  {
    name: `gtc-600-c`,
    video: require(`@/assets/products/PRO/GTC_600_C/GTC_600_C.mp4`),
    images: [
      {
        name: `image-1`,
        src: require(`@/assets/products/PRO/GTC_600_C/1.jpeg`),
      },
      {
        name: `image-2`,
        src: require(`@/assets/products/PRO/GTC_600_C/2.jpeg`),
      },
      {
        name: `image-3`,
        src: require(`@/assets/products/PRO/GTC_600_C/3.jpeg`),
      },
      {
        name: `image-4`,
        src: require(`@/assets/products/PRO/GTC_600_C/4.jpeg`),
      },
    ],
    productName: `GTC 600 C`,
    productReference: `0.601.083.500`,
    mainSection: {
      name: `main`,
      title: `Caméra thermique connecté pour une visualisation détaillée`,
      text: `La caméra thermique GTC 600 C détecte rapidement et facilement les écarts de températures avec sa haute résolution thermique. C’est l’appareil idéal pour détecter les problèmes d’isolation et les ponts thermiques. La caméra est également adaptée aux environnements extérieurs grâce à son design robuste résistant à des chutes d’une hauteur de 2m mais aussi à l’indice de protection IP 54. La connectivité permet de transférer facilement les images thermiques grâce à l’application Bosch Thermal.`
    },
    subSections: [
      {
        name: `haute-resolution`,
        title: `Haute résolution`,
        texts: [
          `La haute résolution (44 800 pixels) permet de donner des images thermiques détaillées avec une très grande sensibilité (< 70mK).`,
        ],
      },
      {
        name: `annotations-vocales`,
        title: `Annotations vocales`,
        texts: [
          `L’enregistrement vocal permet de faciliter la documentation des images enregistrées.`,
        ],
      },
      {
        name: `fonction-image-dans-image`,
        title: `Fonction image dans l’image`,
        texts: [
          `Il est possible d’obtenir simultanément des images réelles et des images thermiques superposées afin de mieux identifier l’endroit visé.`,
        ],
      },
      {
        name: `choix-des-teintes`,
        title: `Choix des teintes`,
        texts: [
          `L’ajustement des couleurs est possible afin de mieux visualiser les détails et les contrastes selon l’environnement dans lequel la détection est effectuée.`,
        ],
      },
    ],
  },
  {
    name: `gis-1000-c`,
    video: require(`@/assets/products/PRO/GIS_1000_C/GIS_1000_C.mp4`),
    images: [
      {
        name: `image-1`,
        src: require(`@/assets/products/PRO/GIS_1000_C/1.jpeg`),
      },
      {
        name: `image-2`,
        src: require(`@/assets/products/PRO/GIS_1000_C/2.jpeg`),
      },
      {
        name: `image-3`,
        src: require(`@/assets/products/PRO/GIS_1000_C/3.jpeg`),
      },
      {
        name: `image-4`,
        src: require(`@/assets/products/PRO/GIS_1000_C/4.jpeg`),
      },
    ],
    productName: `GIS 1000 C`,
    productReference: `0.601.083.300`,
    mainSection: {
      name: `main`,
      title: `Thermomètre laser connecté`,
      text: `Le thermomètre laser GIS 1000 C enregistre des mesures précises des hautes températures (jusqu’à 1000°C). Avec son module photo intégré, il est possible de visualiser instantanément toutes les informations sur l’écran et ainsi documenter les relevés effectués. La connexion Bluetooth permet d’enregistrer les données sur son smartphone via l’application Bosch Thermal ou la prise USB. L’utilisateur peut ainsi utiliser le détecteur thermique pour une grande variété d’application comme détecter le risque de formation de moisissures ou de mesurer par contact la température de surfaces réfléchissantes mais aussi de surveiller le taux d’humidité relatives d’une pièce.`
    },
    subSections: [
      {
        name: `mode-pont-thermique`,
        title: `Mode pont thermique`,
        texts: [
          `Ce mode permet de mesurer les écarts entre la température ambiante et la température de surface d’une fenêtre. Le GIS 1000 C va alors calculer les différences de température, informer l’utilisateur sur la température de point de rosée et calculer le pont thermique. Cela permet de prévenir la formation de moisissures.`,
        ],
      },
      {
        name: `mode-temperature-contact`,
        title: `Mode température de contact`,
        texts: [
          `Cette fonction doit utiliser une sonde de température (Type-K) afin de mesurer les températures. Il est alors possible de mesurer la température de surfaces de métaux réfléchissants mais aussi les températures de liquides et des gaz.`,
        ],
      },
      {
        name: `fonction-alarme`,
        title: `Fonction alarme`,
        texts: [
          `Cette fonction permet de définir une température de surfaces minimale ou maximale. Le détecteur va alors émettre un signal sonore et visuel si la température mesurée est inférieure ou supérieure aux paramètres.`,
        ],
      },
    ],
  },
  {
    name: `gic-120-c`,
    video: require(`@/assets/products/PRO/GIC_120_C/GIC_120_C.mp4`),
    images: [
      {
        name: `image-1`,
        src: require(`@/assets/products/PRO/GIC_120_C/1.jpeg`),
      },
      {
        name: `image-2`,
        src: require(`@/assets/products/PRO/GIC_120_C/2.jpeg`),
      },
      {
        name: `image-3`,
        src: require(`@/assets/products/PRO/GIC_120_C/3.jpeg`),
      },
      {
        name: `image-4`,
        src: require(`@/assets/products/PRO/GIC_120_C/4.jpeg`),
      },
    ],
    productName: `GIC 120 C`,
    productReference: `0.601.241.201`,
    mainSection: {
      name: `main`,
      title: `Caméra d’inspection connecté`,
      text: `La caméra d’inspection peut inspecter et éclairer les endroits grâce au câble de 120 cm avec éclairage LED. La technologie Quick Connector permet un changement facile et rapide du câble de la caméra. Les images et les vidéos peuvent également être directement transmises vers un ordinateurs pour leurs archivages via le câble micro USB et la carte micro SD. Le boitier compact à ergonomie amélioré assure une prise en main de l’appareil dans toutes les situations.`
    },
    subSections: [
      {
        name: `fonction-up-is-up`,
        title: `Fonction up is up`,
        texts: [
          `La fonction garantie une rotation automatique de l’image. Ainsi, le haut de l’image correspond toujours au haut sur la caméra, même si celle-ci venait à tourner.`,
        ],
      },
      {
        name: `quick-connector`,
        title: `Quick Connector`,
        texts: [
          `Cette technologie permet de changer rapidement et facilement de cables optique ou bien de ranger plus aisément la camera.`,
        ],
      },
      {
        name: `zoom`,
        title: `Zoom`,
        texts: [
          `Le zoom numérique permet une meilleure visualisation des details. Il existe 3 zooms : 1.0x, 1.5x, 2.0x`,
        ],
      },
    ],
  },
]
