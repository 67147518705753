import * as THREE from 'three';

let loader = null;

export default async function loadTexture(file) {
  return new Promise(resolve => {
    if (!loader) {
      loader = new THREE.TextureLoader();
      THREE.Cache.enabled = true;
    }

    loader.load(file, texture => resolve(texture));
  });
}
