var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bosch-header",class:{
    visible: _vm.visible,
    splash: _vm.context === "splashScreen",
    pro: _vm.context === "pro",
    diy: _vm.context === "diy",
    mobile: _vm.$breakpoints.isMobile,
  }},[_c('img',{staticClass:"bosch-rainbow",attrs:{"src":require("@/assets/header/head.jpeg"),"alt":"bosch-rainbow"}}),_c('div',{staticClass:"bosch-header-content"},[_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.context !== "pro"),expression:"context !== `pro`"}],staticClass:"bosch-logo",attrs:{"src":require("@/assets/header/logo.png"),"alt":"bosch-logo"}}),_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.context === "pro"),expression:"context === `pro`"}],staticClass:"bosch-logo",attrs:{"src":require("@/assets/header/logo_white.png"),"alt":"bosch-logo"}}),_c('div',{staticClass:"bosch-header-buttons",class:{
        visible: _vm.isInExperience && _vm.visible
      }},[_c('label',{staticClass:"bosch-experience-switch",attrs:{"for":"current-experience"}},[_c('div',[_vm._v("DIY")]),_vm._m(0),_c('div',[_vm._v("PRO")]),_c('input',{attrs:{"type":"checkbox","name":"current-experience","id":"current-experience"},on:{"change":_vm.switchExperience}})]),_c('button',{staticClass:"toggle-button toggle-sound",class:{ clicked: _vm.soundButtonClicked },on:{"click":_vm.toggleSound}},[_c('div',{staticClass:"img-wrapper"},[_c('img',{class:{ visible: !_vm.muted },attrs:{"src":require("@/assets/icons/sound_on.png"),"alt":"sound-on-icon"}}),_c('img',{class:{ visible: _vm.muted },attrs:{"src":require("@/assets/icons/sound_off.png"),"alt":"sound-off-icon"}})]),_c('p',[_vm._v("Sound "+_vm._s(_vm.muted ? "OFF" : "ON"))])]),_c('button',{staticClass:"toggle-button toggle-fullscreen",on:{"click":_vm.toggleFullScreen}},[_c('img',{attrs:{"src":require("@/assets/icons/fullscreen.png"),"alt":"fullscreen-icon"}}),_c('p',[_vm._v("Plein écran")])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"toggle-switch-wrapper"},[_c('div',{staticClass:"toggle-switch-circle"})])}]

export { render, staticRenderFns }