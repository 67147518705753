import * as THREE from 'three'

export default class Cam {
	constructor(ap3) {
		this.init(ap3)
	}

	init(ap3) {
		this.ap3 = ap3
		this.params = {
			iRot_Diy1: {lon: 70, azi: -30},
			iRot_Diy2: {lon: -90, azi: -20},
			iRot_Pro1: {lon: -5, azi: -5},
			iRot_Pro2: {lon: -75, azi: -5},
			aziRange: { min: -30, max: 30 },
			fovRange: { min: 35, max: 90 },
			iFov: 75, // Cam. FOV initial
			speedRot: 0.75, // Vitesse de rotation par rapport au scroll
			speedZoom: 2, // Vitesse du zoom
			safeZoneFactor: 1.5, // % de la largeur d'écran à balayer avant de démarrer le scroll
		}
		this.minAzi = this.params.aziRange.min * Math.PI/180
		this.maxAzi = this.params.aziRange.max * Math.PI/180
		this.hCam0 = new THREE.Object3D()
		this.hCam1 = new THREE.Object3D()
		this.cam = new THREE.PerspectiveCamera( this.params.iFov, this.ap3.sizes.w / this.ap3.sizes.h, 0.1, 11 )
		this.ap3.scene.add(this.hCam0)
		this.hCam0.add(this.hCam1)
		this.hCam1.add(this.cam)
	}

	iniCamRotation(location) {
		const loc = this.params['iRot_' + String(location).charAt(0).toUpperCase() + String(location).slice(1)]
		this.iRotLon = loc.lon * Math.PI/180
		this.iRotAzi = loc.azi * Math.PI/180
		this.iRotation = { x: this.iRotLon, y: this.iRotAzi }
		this.hCam0.rotation.y = this.iRotLon
		this.hCam1.rotation.x = this.iRotAzi
	}

	resize() {
		this.cam.aspect = this.ap3.sizes.w / this.ap3.sizes.h
		this.cam.updateProjectionMatrix()
	}

	zoom(direction) { /////
		this.cam.fov += direction * this.params.speedZoom
		this.cam.fov = Math.min(this.params.fovRange.max, (Math.max(this.params.fovRange.min, this.cam.fov)))
		this.cam.updateProjectionMatrix()
	}

	setIRotation() {
		this.iRotation.x = this.hCam0.rotation.y
		this.iRotation.y = this.hCam1.rotation.x
	}

	tick() {
		this.hCam0.rotation.y = this.iRotation.x + (this.ap3.pointer.pointer.x - this.ap3.pointer.iPointer.x) * Math.PI/2 * this.params.speedRot
		this.hCam1.rotation.x = this.iRotation.y - (this.ap3.pointer.pointer.y - this.ap3.pointer.iPointer.y) * Math.PI/2 * this.params.speedRot
		this.hCam1.rotation.x = Math.min(this.maxAzi, Math.max(this.minAzi, this.hCam1.rotation.x))
	}
}