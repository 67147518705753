<template>
  <div
    class="product-card"
    :class="{
      visible,
      pro: context === `pro`,
      mobile: $breakpoints.isMobile,
    }"
  >
    <div class="product-card-content">
      <div class="carousel-wrapper">
        <image-carousel
          :images="images"
          context="product-card"
          :navigation-enabled="true"
          :navigation-right="`<img class='carousel-arrow' src='${chevronRightImg}' alt='next-slide'/>`"
          :navigation-left="`<img class='carousel-arrow' src='${chevronLeftImg}' alt='prev-slide'/>`"
          pagination-color="#d9d9d9"
          :pagination-color-active="context === `pro` ? `#007bc0` : `#006C3A`"
          :pagination-size="12"
        />
        <button
          class="product-card-video-btn"
          @click="playVideo"
          v-if="video"
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M361 215C375.3 223.8 384 239.3 384 256C384 272.7 375.3 288.2 361 296.1L73.03 472.1C58.21 482 39.66 482.4 24.52 473.9C9.377 465.4 0 449.4 0 432V80C0 62.64 9.377 46.63 24.52 38.13C39.66 29.64 58.21 29.99 73.03 39.04L361 215z"/></svg>
          <p>JOUER LA VIDÉO</p>
        </button>
      </div>
      <div class="content-wrapper">
        <div class="content-sections">
          <div class="product-reference">
            Référence: {{ productReference }}
          </div>
          <div class="main-section">
            <h2>{{ productName }}</h2>
            <h4>{{ mainSection.title }}</h4>
            <p>{{ mainSection.text }}</p>
          </div>
          <div
            class="sub-section"
            v-for="(sub) in subSections"
            :key="`${sub.name}-section`"
          >
            <h3>{{ sub.title }}</h3>
            <p
              v-for="(txt, idx) in sub.texts"
              :key="`${sub.name}-text-${idx}`"
            >
              {{ txt }}
            </p>
          </div>
        </div>
      </div>
      <button class="product-card-close-btn" @click="close">
        <img :src="require(`@/assets/icons/cross.png`)" alt="close-btn">
      </button>
    </div>
  </div>
</template>

<script>
import ImageCarousel from '@/components/ImageCarousel';

export default {
  components: {
    ImageCarousel,
  },

  props: {
    visible: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    context: {
      type: String,
      required: false,
      default: () => `diy`,
    },
    currentProduct: {
      type: String,
      required: true,
    },
    config: {
      type: Array,
      required: true,
    },
  },

  computed: {
    currentConfig() {
      return this.config.find((e) => e.name === this.currentProduct) || null;
    },
    productName() {
      return this.currentConfig?.productName || `defaultProductName`;
    },
    productReference() {
      return this.currentConfig?.productReference || `0000000000`;
    },
    video() {
      return this.currentConfig?.video || null;
    },
    images() {
      return this.currentConfig?.images || null;
    },
    mainSection() {
      return this.currentConfig?.mainSection || null;
    },
    subSections() {
      return this.currentConfig?.subSections || null;
    },
    chevronLeftImg() {
      if (this.context === `diy`) {
        return require(`@/assets/icons/chevron_left_green.svg`);
      }

      return require(`@/assets/icons/chevron_left_blue.svg`)
    },
    chevronRightImg() {
      if (this.context === `diy`) {
        return require(`@/assets/icons/chevron_right_green.svg`);
      }

      return require(`@/assets/icons/chevron_right_blue.svg`)
    },
  },

  mounted() {
  },

  methods: {
    playVideo() {
      this.$emit(`play-video`, {src: this.video, hasSound: true});
    },
    close() {
      this.$emit(`close`);
    }
  }
};
</script>

<style lang="scss" scoped>
.product-card {
  position: fixed;
  z-index: 100;
  top: 85px;
  left: 0;
  right: 0;
  bottom: 0;

  // height: 80vh;
  // width: 80vw;
  // top: 50%;
  // left: 50%;
  // transform: translate(-50%, -50%);
  // max-width: 1200px;
  // margin-top: 40px;
  // display: flex;
  // align-items: center;
  background: rgba(0, 0, 0, 0.6);
  // color: #000;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.5s ease-in-out;

  &.visible {
    opacity: 1;
    pointer-events: all;
  }

  // &::before {
  //   content: '';
  //   position: fixed;
  //   top: 0;
  //   right: 0;
  //   bottom: 0;
  //   left: 0;
  //   background-color: rgba(0, 0, 0, 0.6);
  //   transform-style: preserve-3d;
  //   transform: translateZ(-1px);
  // }

  .product-card-content {
    position: fixed;
    height: 80vh;
    width: 80vw;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 1200px;
    margin-top: 40px;
    display: flex;
    align-items: center;
    background: #e9e9e9;
    color: #000;

    h2, h3 {
      color: #006a39;
    }

    h2 {
      font-size: 30px;
      font-weight: 900;
    }

    h3 {
      font-size: 20px;
      font-weight: 700;
    }

    h4 {
      font-size: 16px;
      font-weight: 700;
    }

    p {
      font-size: 14px;
    }

    .product-reference {
      margin-top: 1em;
      font-size: 12px;
      font-weight: 300;
    }

    .product-card-close-btn {
      position: absolute;
      top: 0;
      right: 0;
      border: none;
      background: #fff;
      width: 60px;
      height: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      img {
        width: 100%;
        transition: width .2s;
      }

      &:hover {
        img {
          width: 80%;
        }
      }
    }

    .carousel-wrapper {
      width: 40%;
      padding: 20px 30px 40px;
      height: 100%;
      background: #fff;

      position: relative;

      .product-card-video-btn {
        width: 15%;
        aspect-ratio: 1 / 1;
        padding: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
        position: absolute;
        bottom: 0;
        right: 0;
        border: 3px solid #006a39;
        background-color: #006a39;
        cursor: pointer;

        transition: background .2s;

        p {
          color: #fff;
          font-size: 7px;
          font-family: BoschSans, Avenir, Helvetica, Arial, sans-serif;
          font-weight: 900;

          transition: color .2s;
        }

        svg {
          width: 30%;
          margin: 5px 0;
          fill: #fff;

          transition: fill .2s;
        }

        &:hover {
          background-color: #fff;

          p {
            color: #006a39
          }

          svg {
            fill: #006a39
          }
        }
      }
    }

    .content-wrapper {
      flex-grow: 1;
      max-height: 90%;
      max-width: 58%;
      padding: 0 50px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      overflow: hidden scroll;

      .content-sections {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        text-align: left !important;
      }

      &::-webkit-scrollbar {
        width: 15px;
      }

      &::-webkit-scrollbar-track,
      &::-webkit-scrollbar-button,
      &::-webkit-scrollbar-corner,
      &::-webkit-resizer {
        background: transparent;
      }

      &::-webkit-scrollbar-thumb {
        background-color: lightgrey;
        border-radius: 20px;
        border: 6px solid transparent;
        background-clip: content-box;
      }
    }
  }

  &.pro .product-card-content {
    h2, h3 {
      color: #007bc0;
    }

    .carousel-wrapper {
      .product-card-video-btn {
        border-color: #007bc0;
        background-color: #007bc0;

        &:hover {
          background-color: #fff;

          p {
            color: #007bc0;
          }

          svg {
            fill: #007bc0;
          }
        }
      }
    }
  }

  &.mobile {
    top: 75px;

    .product-card-content {
      width: calc(100vw - 50px);
      height: calc((100% - 75px) - 50px);
      flex-direction: column;
      background-color: #ffffff;

      .carousel-wrapper {
        width: 100%;
        padding: 0;
        height: calc(55% - 60px);
        margin-bottom: 60px;
        background-color: #ffffff;

        .product-card-video-btn {
          border: none;
          width: 60px;
          height: 60px;
          transform: translate(0, 100%);
          justify-content: center;

          svg {
            width: 25%;
            margin: 3px 0;
          }

          p {
            margin: 0;
          }
        }
      }

      .content-wrapper {
        width: 100%;
        max-width: unset;
        max-height: 45%;
        background-color: #e9e9e9;
        padding: 20px 30px;

        .product-reference {
          margin-top: 0;
          margin-bottom: 0.5em;
        }

        .main-section {
          h2 {
            margin: 0;
            font-size: 24px;
            margin-bottom: 0.3em;
          }

          h4 {
            margin: 1em 0;
            font-size: 14px;
          }
        }

        .sub-section {
          h3 {
            font-size: 16px;
          }

          &:last-child {
            margin-bottom: 1em;
          }
        }

        p {
          font-size: 12px;
          margin: 0.5em 0;
        }

        &::after {
          content: '';
          position: absolute;
          bottom: -1px;
          left: 0;
          right: 0;
          height: 60px;
          background: linear-gradient(0deg, #e9e9e9, #e9e9e9 20%, rgba(0, 0, 0, 0));
          overflow: hidden;
        }
      }
    }
  }
}
</style>

<style lang="scss">
.carousel-wrapper {
  .VueCarousel {
    height: 100%;
    justify-content: center;

    .VueCarousel-wrapper {

      .VueCarousel-inner {
        max-height: 100%;
      }

      .VueCarousel-slide {
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }

    .VueCarousel-navigation-button {
      height: 26px;
      padding: 0 !important;
      margin: 0;

      &.VueCarousel-navigation-prev {
        transform: translate(100%, -50%);
      }

      &.VueCarousel-navigation-next {
        transform: translate(-100%, -50%);
      }

      img {
        height: 26px;
      }

      &:focus {
        outline: none;
      }
    }
  }
}

.product-card.mobile {
  .carousel-wrapper {
    .VueCarousel {
      .VueCarousel-pagination {
        transform: translate(0, 100%);
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        .VueCarousel-dot-container {
          margin: 0 !important;

          .VueCarousel-dot {
            margin: 0 !important;
            padding: 3px !important;
            width: 8px !important;
            height: 8px !important;
          }
        }
      }
    }
  }
}
</style>