import * as THREE from 'three'
import Time from './Time'
import Sizes from './Sizes'
import Cam from './Cam'
import Renderer from './Renderer'
import AssetsLoader from './AssetsLoader'
import Sphere from './Sphere'
import Pointer from './Pointer'
import Ray from './Ray'

export default class Ap3 {
	constructor(canvas) {
		this.canvas = canvas
		this.start()
	}

	start() {
		this.scene = new THREE.Scene()
		this.time = new Time(this)
		this.sizes = new Sizes(this)
		this.cam = new Cam(this)
		this.renderer = new Renderer(this)
		this.pointer = new Pointer(this)
		this.ray = new Ray(this)
		this.assetsLoader = new AssetsLoader()

		this.time.addEvent('render')
		this.time.addEvent('ray')
	}

	addAssets(assets, handler) { return this.assetsLoader.load(assets, handler) }

	setSphere(sphereAssets) {
		this.sphere = new Sphere(this, sphereAssets)
	}
}