
import VueGtagPlugin from 'vue-gtag';

const GA_ID = process.env.VUE_APP_GA_ID || null;

export default {
  install(Vue) {
    let isGtagEnabled = false;
    let langSuffix = ``;
    if (GA_ID && GA_ID.length) {
      Vue.use(VueGtagPlugin, {
        config: { id: GA_ID },
      });
      isGtagEnabled = true;
    }
    Vue.prototype.$gtagLang = langKey => {
      langSuffix = `_${langKey.toUpperCase()}`;
    };
    Vue.prototype.$gtagEvent = function(action, options = {}) {
      if (isGtagEnabled) {
        const actionName = `${action}${langSuffix}`;
        this.$gtag.event(actionName, {
          event_label: actionName,
          ...options,
        });
      }
    };
  },
};
