import EvEmitter from './EvEmitter'

export default class Time extends EvEmitter {
	constructor(ap3) {
		super()
		this.init(ap3)
		this.tickActions()
		this.tick()
	}

	init(ap3) {
		this.ap3 = ap3
		this.enabled = false
		this.start = Date.now()
		this.previous = this.start
		this.delta = 16
		this.elapsed = 0
		this.events = []
	}

	addEvent(event) { if(!this.events.includes(event)) { this.events.push(event) } }
	remEvent(event) { this.events = this.events.filter(e => e !== event) }

	tickActions() {
		this.on('render', () => this.ap3.renderer.tick())
		this.on('ray', () => this.ap3.ray.tick())
		this.on('pointing', () => this.ap3.pointer.tickPointing())
		this.on('dragging', () => this.ap3.cam.tick())
		this.on('fading', () => this.ap3.sphere.tick())
	}

	tick() {
		if (!this.enabled) { return }
		const currentTime = Date.now()
		this.delta = currentTime - this.previous
		this.previous = currentTime
		this.elapsed = this.previous - this.start
		for (let i = 0; i < this.events.length; i++) {
			this.trigger(this.events[i])
		}
		window.requestAnimationFrame(() => { this.tick() })
	}

	startTicking() {
		if (this.enabled) { return }
		this.enabled = true
		this.tick()
	}

	stopTicking() {
		this.enabled = false
	}
}