export default [
  {
    name: `zamo`,
    video: require(`@/assets/products/DIY/Zamo/zamo.mp4`),
    images: [
      {
        name: `image-1`,
        src: require(`@/assets/products/DIY/Zamo/1.jpeg`),
      },
      {
        name: `image-2`,
        src: require(`@/assets/products/DIY/Zamo/2.jpeg`),
      },
      {
        name: `image-3`,
        src: require(`@/assets/products/DIY/Zamo/3.jpeg`),
      },
      {
        name: `image-4`,
        src: require(`@/assets/products/DIY/Zamo/4.jpeg`),
      },
    ],
    productName: `Télémètre laser Zamo`,
    productReference: `0603672700`,
    mainSection: {
      name: `main`,
      title: `Mesures des distances et des surfaces de manière extrêmement simple`,
      text: `Très pratique, le télémètre laser Zamo allie précision et facilité d’utilisation. Sa technologie laser permet de réaliser des mesures très précises. Le Zamo accepte trois adaptateurs Bosch brevetés couvrant une grande variété d’applications : un adaptateur roulette, un adaptateur mètre ruban et un adaptateur ligne laser (vendus séparément). Très facile à utiliser, le télémètre se commande de manière intuitive à l’aide d’un bouton unique.`
    },
    subSections: [
      {
        name: `mesures-simples`,
        title: `Mesures simples`,
        texts: [
          `Mesurer en intérieur des distances jusqu’à 20 m en quelques secondes.`,
        ],
      },
      {
        name: `mesures-precises`,
        title: `Mesures précises sur une grande variété de projets`,
        texts: [
          `Mesurer des emplacements avant de monter des meubles, étagères ou œuvres d’art.`,
        ],
      },
      {
        name: `calcul-superficie`,
        title: `Calcul de superficie`,
        texts: [
          `Calculer par simple double-clic des surfaces avant de peindre ou tapisser.`,
        ],
      },
    ],
  },
  {
    name: `plr-50-c`,
    video: require(`@/assets/products/DIY/PLR_50_C/PLR_50_C.mp4`), // remplacer par le chemin de la vidéo associée au produit
    images: [
      {
        name: `image-1`,
        src: require(`@/assets/products/DIY/PLR_50_C/1.png`), // remplacer par le chemin de la première image, idem pour les images 2, 3, 4 ... x
      },
      {
        name: `image-2`,
        src: require(`@/assets/products/DIY/PLR_50_C/2.png`),
      },
      {
        name: `image-3`,
        src: require(`@/assets/products/DIY/PLR_50_C/3.png`),
      },
      {
        name: `image-4`,
        src: require(`@/assets/products/DIY/PLR_50_C/4.png`),
      },
    ],
    productName: `Télémètre laser PLR 50C`,
    productReference: `0603672200`,
    mainSection: {
      name: `main`,
      title: `La manière intelligente de mesurer`,
      text: `Son écran tactile intuitif et ses fonctions intelligentes permettent de mesurer très rapidement des distances et calculer des surfaces ou volumes. Ce télémètre est capable d’additionner ou soustraire des résultats, d’afficher des angles d’inclinaison et même de mesurer indirectement des distances jusqu’à 50 m.`
    },
    subSections: [
      {
        name: `precision`, // purement config, pas affiché
        title: `Précision`,
        texts: [
          `Mesures de distances précises par laser jusqu’à 50 m.`, // Une entrée de tableau par paragraphe dans la section
        ],
      },
      {
        name: `multiples-fonctionnalites`,
        title: `Multiples fonctionnalités`,
        texts: [
          `Fonctions de mesure standard intégrées, y compris mesures indirectes.`,
        ],
      },
      {
        name: `connectivite-bluetooth`,
        title: `Connectivité Bluetooth`,
        texts: [
          `Grâce la fonction de connectivité, les valeurs mesurées et calculées peuvent être transférées par Bluetooth à une application Bosch.`,
        ],
      },
    ],
  },
  {
    name: `truvo`,
    video: require(`@/assets/products/DIY/Truvo/Truvo.mp4`),
    images: [
      {
        name: `image-1`,
        src: require(`@/assets/products/DIY/Truvo/1.jpeg`),
      },
      {
        name: `image-2`,
        src: require(`@/assets/products/DIY/Truvo/2.jpeg`),
      },
      {
        name: `image-3`,
        src: require(`@/assets/products/DIY/Truvo/3.jpeg`),
      },
      {
        name: `image-4`,
        src: require(`@/assets/products/DIY/Truvo/4.jpeg`),
      },
    ],
    productName: `Détecteur Truvo`,
    productReference: `0603681201`,
    mainSection: {
      name: `main`,
      title: `Allumer, détecter, percer !`,
      text: `Le détecteur Truvo permet d’inspecter rapidement les murs et plafonds. Capable de localiser facilement des objets métalliques et câbles sous courant alternatif, il offre l’assurance de pouvoir percer en toute sécurité pour suspendre des cadres photo ou d’installer des luminaires. La manipulation est intuitive grâce au fonctionnement simple à un bouton.`
    },
    subSections: [
      {
        name: `detection-facile-rapide`,
        title: `Détection facile et rapide`,
        texts: [
          `Détection facile des câbles sous tension et objets métalliques.`,
        ],
      },
      {
        name: `resultats-visuels-sonores`,
        title: `Résultats visuels et sonores`,
        texts: [
          `Une LED tricolore et un signal d’avertissement sonore indiquent les endroits où ne pas percer.`,
        ],
      },
      {
        name: `auto-calibration`,
        title: `Auto-calibration`,
        texts: [
          `Auto-calibrage très pratique permettant de démarrer aussitôt.`,
        ],
      },
    ],
  },
  {
    name: `universal-humid`,
    video: require(`@/assets/products/DIY/UniversalHumid/UniversalHumid_1.mp4`),
    images: [
      {
        name: `image-1`,
        src: require(`@/assets/products/DIY/UniversalHumid/1.png`),
      },
      {
        name: `image-2`,
        src: require(`@/assets/products/DIY/UniversalHumid/2.png`),
      },
      {
        name: `image-3`,
        src: require(`@/assets/products/DIY/UniversalHumid/3.png`),
      },
      {
        name: `image-4`,
        src: require(`@/assets/products/DIY/UniversalHumid/4.png`),
      },
    ],
    productName: `UniversalHumid`,
    productReference: `0603688000`,
    mainSection: {
      name: `main`,
      title: `Un outil intuitif pour mesurer avec précision l’humidité du bois !`,
      text: `L'humidimètre bois UniversalHumid permet de vérifier et d'évaluer le taux d'humidité de vos bois. Cet humidimètre bois vous permet d'éviter la formation de fumée dans votre bois de chauffe et de prévenir la production de moisissures. Il s'agit d'un outil intuitif grâce l'autocollant situé à l’arrière du détecteur qui indique les types de bois les plus courants pour chaque groupe sélectionné A ou B pour des résultats précis.`
    },
    subSections: [
      {
        name: `led`,
        title: `LED tricolore (rouge, jaune et vert)`,
        texts: [
          `Une interprétation simple et rapide des LED de l'appareil pour renseigner le taux d’humidité.`,
          `Vert : sec (<12%)`,
          `Jaune : alarmant (12-20%)`,
          `Rouge : humide (>20%)`,
        ],
      },
      {
        name: `plage-mesure`,
        title: `Plage de mesure`,
        texts: [
          `A : 7,1 % à 74.7 %`,
        ],
      },
      {
        name: `groupes-bois`,
        title: `Deux groupes de bois`,
        texts: [
          `A : érable, bouleau, mélèze, douglas, sapin, cerisier, épicéa`,
          `B : frêne, pin, chêne, grumes de noyer, hêtre`,
        ],
      },
    ],
  },
  {
    name: `universal-inspect`,
    video: require(`@/assets/products/DIY/UniversalInspect/UniversalInspect.mp4`),
    images: [
      {
        name: `image-1`,
        src: require(`@/assets/products/DIY/UniversalInspect/1.jpeg`),
      },
      {
        name: `image-2`,
        src: require(`@/assets/products/DIY/UniversalInspect/2.jpeg`),
      },
      {
        name: `image-3`,
        src: require(`@/assets/products/DIY/UniversalInspect/3.jpeg`),
      },
      {
        name: `image-4`,
        src: require(`@/assets/products/DIY/UniversalInspect/4.jpeg`),
      },
    ],
    productName: `UniversalInspect`,
    productReference: `0603687000`,
    mainSection: {
      name: `main`,
      title: `Visibilité parfaite dans les endroits difficiles d’accès`,
      text: `Facile à utiliser, la caméra UniversalInspect assure une visibilité parfaite dans les endroits difficiles d’accès. Elle permet de voir ce qui se cache dans des espaces exigus, de rechercher des objets perdus, de localiser d’éventuels bouchons dans des canalisations, de détecter des dommages dans des flexibles de véhicules ou d’inspecter des cavités dans des faux.`
    },
    subSections: [
      {
        name: `bonne-visibilite`,
        title: `Bonne visibilité`,
        texts: [
          `Le zoom numérique et l’éclairage LED sur la tête de la caméra améliorent la clarté et la visibilité en cours d’utilisation.`,
        ],
      },
      {
        name: `memoire-interne`,
        title: `Mémoire interne`,
        texts: [
          `Capacité de stockage interne de 8 images et possibilité de transfert de données via une carte micro-SD.`,
        ],
      },
      {
        name: `accessorisee`,
        title: `Accessorisée`,
        texts: [
          `Pour supprimer les bouchons ou récupérer des objets dans des canalisations (crochet, aimant, miroir, 2 manchons).`,
        ],
      },
    ],
  },
  {
    name: `detecteur-ptd`,
    video: require(`@/assets/products/DIY/PTD1/PTD_1.mp4`),
    images: [
      {
        name: `image-1`,
        src: require(`@/assets/products/DIY/PTD1/1.jpeg`),
      },
      {
        name: `image-2`,
        src: require(`@/assets/products/DIY/PTD1/2.jpeg`),
      },
      {
        name: `image-3`,
        src: require(`@/assets/products/DIY/PTD1/3.jpeg`),
      },
      {
        name: `image-4`,
        src: require(`@/assets/products/DIY/PTD1/4.jpeg`),
      },
    ],
    productName: `Détecteur AdvancedTemp`,
    productReference: `0603683200`,
    mainSection: {
      name: `main`,
      title: `Économisez de l’énergie et vivez plus sainement`,
      text: `Le nouveau détecteur thermique AdvancedTemp compare les températures de surface, détecte les ponts thermiques et les risques de moisissure. Le produit mesure facilement la température de surface, ambiante et le taux d'humidité ambiant. Les résultats sont précis en raison d'une sélection parmi trois valeurs d'émissivité. La manipulation est intuitive et pratique grâce à la matérialisation de la zone de mesure par un cercle laser. Le produit et son emballage sont constitués en parti de matériaux recyclés pour un meilleur respect de l‘environnement.`
    },
    subSections: [
      {
        name: `localisation-risques-moisissure`,
        title: `Localisation des risques de moisissure`,
        texts: [
          `La température ambiante et l'humidité relative sont mesurées, ce qui permet de localiser les endroits propices à l’apparition de moisissures.`,
        ],
      },
      {
        name: `polyvalence-de-détectione`,
        title: `Polyvalence de détection`,
        texts: [
          `Quatres différents modes de mesure pour un large éventail d'applications.`,
        ],
      },
      {
        name: `resultat-visuel`,
        title: `Résultat visuel`,
        texts: [
          `Grâce à la LED tricolore, les résultats affichés sur l’écran sont faciles à interpréter.`,
        ],
      },
    ],
  },
  {
    name: `atino`,
    video: require(`@/assets/products/DIY/Atino/Atino.mp4`),
    images: [
      {
        name: `image-1`,
        src: require(`@/assets/products/DIY/Atino/1.png`),
      },
      {
        name: `image-2`,
        src: require(`@/assets/products/DIY/Atino/2.png`),
      },
      {
        name: `image-3`,
        src: require(`@/assets/products/DIY/Atino/3.png`),
      },
      {
        name: `image-4`,
        src: require(`@/assets/products/DIY/Atino/4.png`),
      },
    ],
    productName: `Atino`,
    productReference: `0603663A00`,
    mainSection: {
      name: `main`,
      title: `Accrochez vos objets favoris sans aucune aide en un minimum de temps`,
      text: `Atino est un mètre laser ligne innovant, confectionné à partir des retours utilisateurs. Il s'agit d'un outil tout en un pour une fixation, mise à niveau et marquage simple et rapide sans avoir besoin de plus de deux mains. Fixez Atino à votre mur grâce à son gel pad sur les surfaces lisses et insensibles ou avec des épingles sur vos papiers peints ou surfaces rugueuses, tracez le premier trou de marquage au milieu de l'outil, dépliez votre mètre ruban et alignez parfaitement vos besoins.`
    },
    subSections: [
      {
        name: `praticite`,
        title: `Praticité`,
        texts: [
          `Permet d’aligner des objets sur un mur facilement sans aucune aide.`,
        ],
      },
      {
        name: `metre-ruban-integre`,
        title: `Mètre ruban intégré`,
        texts: [
          `Atino possède toutes les fonctions de base d’un laser lignes et intègre en plus un mètre ruban de 1,5 m.`,
        ],
      },
      {
        name: `anneau-led`,
        title: `Anneau de LED`,
        texts: [
          `Son anneau LED intuitif permet d’effectuer facilement des mises à niveau verticales et horizontales avec une précision de la ligne laser visible de ± 0,3°.`,
        ],
      },
    ],
  },
  {
    name: `quigo-green`,
    video: require(`@/assets/products/DIY/Quigo_green/Quigo_Green.mp4`),
    images: [
      {
        name: `image-1`,
        src: require(`@/assets/products/DIY/Quigo_green/1.jpeg`),
      },
      {
        name: `image-2`,
        src: require(`@/assets/products/DIY/Quigo_green/2.jpeg`),
      },
      {
        name: `image-3`,
        src: require(`@/assets/products/DIY/Quigo_green/3.jpeg`),
      },
      {
        name: `image-4`,
        src: require(`@/assets/products/DIY/Quigo_green/4.jpeg`),
      },
    ],
    productName: `Quigo green`,
    productReference: `0603663C02`,
    mainSection: {
      name: `main`,
      title: `Feu vert pour vos idées !`,
      text: `Le nouveau laser croix Quigo green est petit et compact. Il s'agit d'un laser lignes autonivelant pour un alignement rapide et facile sur le mur, avec une technologie faisceau vert pour une grande visibilité même dans un environnement lumineux. Idéal pour tous ceux qui souhaitent mener à bien des projets d'alignement dans des conditions d'éclairage intense, de manière simple et rapide. Premier produit MT à mettre en oeuvre des aspects clés de la durabilité, notamment des plastiques recyclés pour le boîtier et les accessoires et un emballage écologique.`
    },
    subSections: [
      {
        name: `precision`,
        title: `Précision`,
        texts: [
          `Portée de fonctionnement étendue jusqu'à 12 m grâce à des lignes laser vertes plus visibles.`,
        ],
      },
      {
        name: `auto-nivellement`,
        title: `Auto-nivellement`,
        texts: [
          `Croix laser avec mise à niveau automatique.`,
        ],
      },
      {
        name: `technologie-laser-vert`,
        title: `Technologie laser vert`,
        texts: [
          `Technologie laser vert pour une visibilité jusqu’à 4 fois meilleure qu’avec des lignes laser rouges, même dans des conditions de forte luminosité.`,
        ],
      },
    ],
  },
  {
    name: `universal-level-3`,
    video: require(`@/assets/products/DIY/UniversalLevel_3/UniversalLevel_3.mp4`),
    images: [
      {
        name: `image-1`,
        src: require(`@/assets/products/DIY/UniversalLevel_3/1.jpeg`),
      },
      {
        name: `image-2`,
        src: require(`@/assets/products/DIY/UniversalLevel_3/2.jpeg`),
      },
      {
        name: `image-3`,
        src: require(`@/assets/products/DIY/UniversalLevel_3/3.jpeg`),
      },
      {
        name: `image-4`,
        src: require(`@/assets/products/DIY/UniversalLevel_3/4.jpeg`),
      },
    ],
    productName: `UniversalLevel 3`,
    productReference: `0603663900`,
    mainSection: {
      name: `main`,
      title: `Le niveau laser de précision pour des alignements, équerrages et reports faciles`,
      text: `L’UniversalLevel 3 Bosch facilite l’exécution de travaux exigeants, comme la pose de carrelages, l’installation d’une cabine de douche ou d’une cloison sèche. Avec sa croix laser et sa seconde ligne verticale, le laser lignes peut être utilisé pour la réalisation d’alignements horizontaux, verticaux et à angle droit. La seconde ligne laser verticale, à 90° de la première, constitue une ligne de référence pour des alignements précis dans n’importe quelle pièce.`
    },
    subSections: [
      {
        name: `precision`,
        title: `Précision`,
        texts: [
          `Réalisation d’alignements horizontaux, verticaux et à angle droit avec une portée jusqu’à 10 m.`,
        ],
      },
      {
        name: `fonction-point-aplomb`,
        title: `Fonction point d’aplomb`,
        texts: [
          `Les lignes et le point d’aplomb s’ajustent automatiquement au bout d’un court laps de temps et sont corrects à 100 %.`,
        ],
      },
      {
        name: `fonction-inclinaison`,
        title: `Fonction d’inclinaison`,
        texts: [
          `Fonction d’inclinaison pour les travaux dépassant la plage de mise à niveau automatique.`,
        ],
      },
    ],
  },
  {
    name: `universal-level-360`,
    video: require(`@/assets/products/DIY/UniversalLevel_360/UniversalLevel_360.mp4`),
    images: [
      {
        name: `image-1`,
        src: require(`@/assets/products/DIY/UniversalLevel_360/1.png`),
      },
      {
        name: `image-2`,
        src: require(`@/assets/products/DIY/UniversalLevel_360/2.png`),
      },
      {
        name: `image-3`,
        src: require(`@/assets/products/DIY/UniversalLevel_360/3.png`),
      },
      {
        name: `image-4`,
        src: require(`@/assets/products/DIY/UniversalLevel_360/4.png`),
      }
    ],
    productName: `UniversalLevel 360`,
    productReference: `0603663E00`,
    mainSection: {
      name: `main`,
      title: `Pour des alignements de grande précision avec une très bonne visibilité dans toute la pièce`,
      text: `Avec ses lignes laser parfaitement visibles pour des alignements de grande précision dans toute une pièce, le laser lignes UniversalLevel 360 allie précision, polyvalence et confort. La ligne laser horizontale sur 360° et la ligne verticale sur 120° toutes deux auto-nivelantes qui sont projetées permettent d’effectuer rapidement et avec commodité des alignements précis.`
    },
    subSections: [
      {
        name: `grande-portee`,
        title: `Grande portée`,
        texts: [
          `Faisceau vert allant jusqu’à 24 m de diamètre.`,
        ],
      },
      {
        name: `precision`,
        title: `Précision`,
        texts: [
          `Lignes laser parfaitement à niveau avec une précision de +/- 0,4 mm/m.`,
        ],
      },
      {
        name: `visibilite-accrue`,
        title: `Visibilité accrue`,
        texts: [
          `Diodes laser vertes 4 fois plus visible même en cas de forte luminosité que les diodes laser rouges.`,
        ],
      },
    ],
  },
  {
    name: `advanced-level-360`,
    video: require(`@/assets/products/DIY/AdvancedLevel_360/AdvancedLevel_360.mp4`),
    images: [
      {
        name: `image-1`,
        src: require(`@/assets/products/DIY/AdvancedLevel_360/1.jpeg`),
      },
      {
        name: `image-2`,
        src: require(`@/assets/products/DIY/AdvancedLevel_360/2.jpeg`),
      },
      {
        name: `image-3`,
        src: require(`@/assets/products/DIY/AdvancedLevel_360/3.jpeg`),
      },
      {
        name: `image-4`,
        src: require(`@/assets/products/DIY/AdvancedLevel_360/4.jpeg`),
      },
    ],
    productName: `AdvancedLevel 360`,
    productReference: `0603663B03`,
    mainSection: {
      name: `main`,
      title: `L’outil polyvalent pour des alignements précis`,
      text: `L’AdvancedLevel 360 réunit en un seul outil toutes les caractéristiques requises pour des alignements, équerrages et reports précis. La ligne laser horizontale sur 360° et la ligne verticale garantissent des alignements très précis. La seconde ligne laser verticale, perpendiculaire à la première, permet des équerrages précis et la projection d’une croix de référence au plafond en complément du point d’aplomb vers le bas.`
    },
    subSections: [
      {
        name: `portee-elargie`,
        title: `Portée élargie`,
        texts: [
          `Faisceau vert allant jusqu’à 24 m de diamètre.`,
        ],
      },
      {
        name: `fonction-inclinaison`,
        title: `Fonction d’inclinaison`,
        texts: [
          `Fonction d’inclinaison pour les travaux dépassant la plage de mise à niveau automatique.`,
        ],
      },
      {
        name: `technologie-laser-verte`,
        title: `Technologie laser verte`,
        texts: [
          `La technologie laser verte assure une visibilité jusqu’à 4 fois meilleure qu’avec des lignes laser rouges, même par forte luminosité.`,
        ],
      },
    ],
  },
]
