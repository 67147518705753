import * as THREE from 'three'
import Mats from './Mats'

export default class Sphere {
	constructor(ap3, assets) {
		this.init(ap3)
		this.setMats(assets.mats, assets.texs)
		this.deployGlb(assets.glbs)
	}

	init(ap3) {
		this.ap3 = ap3
		this.params = {
			opacityArrow: { normal: 0.5, hover: 1 },
			opacityGlow: { normal: 0, hover: 0.5 },
			durationFadeIn: 0.333,
			durationFadeOut: 0.25,
		}
		this.fading = []
		this.fadingData = {}
	}

	setMats(mats, texs) {
		this.matsSphere = new Mats(mats, texs)
		this.matsSphere.mats.glow.opacity = this.params.opacityGlow.normal
		this.matsSphere.mats.arrow.opacity = this.params.opacityArrow.normal
		for(let i = 0 ; i<7 ; i++) {
			const string = 'matGlow'+(i+1)
			this[string] = new THREE.MeshBasicMaterial()
			this[string].copy(this.matsSphere.mats.glow)
			this[string].name = string
		}
	}

	deployGlb(glbs) {
		glbs.sphere.scene.traverse((o) => {
			this[o.name] = o
			if(o.isMesh) {
				if(o.name == 'fond') { o.material = this.matsSphere.mats.diy1 } else
				if(o.name.includes('arrow')) { o.material = this.matsSphere.mats.arrow } else
				if(o.name.includes('zone')) { o.visible = false } else
				if(o.name.includes('glow1')) { o.material = this.matGlow1 } else
				if(o.name.includes('glow2')) { o.material = this.matGlow2 } else
				if(o.name.includes('glow3')) { o.material = this.matGlow3 } else
				if(o.name.includes('glow4')) { o.material = this.matGlow4 } else
				if(o.name.includes('glow5')) { o.material = this.matGlow5 } else
				if(o.name.includes('glow6')) { o.material = this.matGlow6 } else
				if(o.name.includes('glow7')) { o.material = this.matGlow7 }
			}
		})
		this.ap3.scene.add(this.fond)
	}

	setLocation(location) {
		this.fond.material = this.matsSphere.mats[location]
		this.ap3.ray.castList = []
		this.ap3.scene.traverse((o) => { if(o.name == 'hDiy1' || o.name == 'hDiy2' || o.name == 'hPro1' || o.name == 'hPro2') { this.ap3.scene.remove(o) } })
		const loc = 'h' + String(location).charAt(0).toUpperCase() + String(location).slice(1)
		this.ap3.scene.add(this[loc])
		this[loc].traverse((o) => { if(o.isMesh && (o.name.includes('arrow') || o.name.includes('zone'))) { this.ap3.ray.castList.push(o) } })
		this.ap3.cam.iniCamRotation(location)
	}

	fade(object, direction) {
		if(object.name.includes('zone')) { object = this[object.name.replace('zone', 'glow')] }
		if(!this.fading.includes(object)) { this.fading.push(object) }
		this.fadingData[object.name] = { iniOpacity: object.material.opacity, startTime: this.ap3.time.elapsed }
		if(direction == 'in' && object.name.includes('glow')) {
			this.fadingData[object.name].targetOpacity = this.params.opacityGlow.hover
			this.fadingData[object.name].durationFade = this.params.durationFadeIn * 1000
		} else if(direction == 'in' && object.name.includes('arrow')) {
			this.fadingData[object.name].targetOpacity = this.params.opacityArrow.hover
			this.fadingData[object.name].durationFade = this.params.durationFadeIn * 1000
		} else if (direction == 'out' && object.name.includes('glow')) {
			this.fadingData[object.name].targetOpacity = this.params.opacityGlow.normal
			this.fadingData[object.name].durationFade = this.params.durationFadeOut * 1000
		} else {
			this.fadingData[object.name].targetOpacity = this.params.opacityArrow.normal
			this.fadingData[object.name].durationFade = this.params.durationFadeOut * 1000
		}
		this.ap3.time.addEvent('fading')
	}

	tick() {
		for(const o of this.fading) { 
			const taux = (this.ap3.time.elapsed - this.fadingData[o.name].startTime) / this.fadingData[o.name].durationFade
			o.material.opacity = THREE.MathUtils.lerp(this.fadingData[o.name].iniOpacity, this.fadingData[o.name].targetOpacity, taux)
			if(taux > 1) {
				o.material.opacity = this.fadingData[o.name].targetOpacity
				this.fading = this.fading.filter(e => e != o)
			}
		}
		if(!this.fading.length) { this.ap3.time.remEvent('fading') }
	}
}