import { render, staticRenderFns } from "./SpacesScene.vue?vue&type=template&id=213e01c4&scoped=true&"
import script from "./SpacesScene.vue?vue&type=script&lang=js&"
export * from "./SpacesScene.vue?vue&type=script&lang=js&"
import style0 from "./SpacesScene.vue?vue&type=style&index=0&id=213e01c4&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "213e01c4",
  null
  
)

export default component.exports